import { createSlice } from '@reduxjs/toolkit';
import { DouglasPeucker } from '../utilities/PathCompression';
import { FlightAnimationData } from '../components/paths/FlightAnimationData';
import * as THREE from "three";
const MAX_POINTS = 500;


const initialState = {
    paths: {},
    visibilityMatrix: {},
    highlightedPathName: ""
}



const flightDataSlice = createSlice({
    name: 'droneData',
    initialState,
    reducers: {
        updateFlightData: (state, action) => {
            const
                {
                    flightName,
                    flightFile,
                    compressionFactor
                } = action.payload;


            //calculate the path points and curve
            let request = new XMLHttpRequest();
            request.open("GET", process.env.PUBLIC_URL + "/" + flightFile, false);
            request.send(null);
            let flightObj = JSON.parse(request.responseText);
            const ratio = Math.floor(flightObj.length / MAX_POINTS);


            let points = [];

            for (let i = 0; points.length < MAX_POINTS; i += ratio) {
                let element = flightObj[i];
                let velX = 0;
                let velY = 0;
                let velZ = 0;
                if (i !== 0 && i !== MAX_POINTS){
                    let previousElement = flightObj[i - ratio];
                    let nextElement = flightObj[i + ratio];
                    let dT = parseFloat(nextElement.t) - parseFloat(previousElement.t);
                    velX = (parseFloat(nextElement.x) - parseFloat(previousElement.x)) / dT;
                    velY = (parseFloat(nextElement.y) - parseFloat(previousElement.y)) / dT;
                    velZ = (parseFloat(nextElement.z) - parseFloat(previousElement.z)) / dT;
                }
                let s = Math.sqrt((velX ** 2) + (velY ** 2) + (velZ ** 2));
                let point = {
                    x: parseFloat(element.x), y: parseFloat(element.y), z: parseFloat(element.z),
                    time: parseFloat(element.t), pitch: parseFloat(element.pitch), roll: parseFloat(element.roll),
                    yaw: parseFloat(element.yaw), vX: velX, vY: velY, vZ: velZ, speed: s,
                };
                points.push(point);
            }


            // console.log("Before Compression (factor of "
            //     + compressionFactor + "): " + points.length);

            let flightAnimationData = undefined;

            //try to compress points
            if (compressionFactor > 0) {
                points = DouglasPeucker(points, compressionFactor);
                // console.log("Creating Animation Data...");
                flightAnimationData = new FlightAnimationData(points);
                // console.log("...Done");
            }
            // console.log("After Compression (factor of "
            //     + compressionFactor + "): " + points.length);



            //construct vectors for the curve
            let vectors = [];
            for (let i = 0; i < points.length; i++) {
                vectors.push(new THREE.Vector3(points[i].x, points[i].y, points[i].z));
            }
            let curve = new THREE.CatmullRomCurve3(vectors);


            state.paths[flightName] = {
                flightName,
                curve,
                flightAnimationData,
            };
            state.visibilityMatrix[flightName] = true;

            if (state.highlightedPathName == "") state.highlightedPathName = flightName;

        },
        setVisible: (state, action) => {
            const { flightName, visible } = action.payload;
            state.visibilityMatrix[flightName] = visible;
        },
        setHighlightedPathName: (state, action) => { state.highlightedPathName = action.payload; }
    }
});

export const selectHighlightedPath = (state) => {
    if (state.FlightData.paths) {
        return state.FlightData.paths[state.FlightData.highlightedPathName];
    }
    else {
        return undefined;
    }

};
export const selectFlights = (state) => state.FlightData.paths;
export const selectHighlightedPathName = (state) => state.FlightData.highlightedPathName;
export const { updateFlightData, setHighlightedPathName, setVisible } = flightDataSlice.actions;
export const selectVisibilityMatrix = (state) => state.FlightData.visibilityMatrix;
export default flightDataSlice.reducer;