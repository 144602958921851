import { Canvas, useFrame } from "@react-three/fiber";
import React, { Suspense, useEffect, useRef } from "react";
import { Model } from "../model/Model";
import { Loading } from "../misc/Loading";
import { Environment, OrbitControls } from "@react-three/drei";
import { useContextBridge } from "@react-three/drei";
import { ReactReduxContext, useDispatch, useSelector } from "react-redux";
import { ViewCube } from "../misc/Viewcube";
import {
  selectResetView,
  setPos,
  setResetView,
  setRot,
} from "../../reducers/CameraDataReducer";
import { selectCurrentTab } from "../../reducers/TabDataReducer";

export const AlignViewer = (props) => {
  const orbitControls = useRef();
  const ContextBridge = useContextBridge(ReactReduxContext);
  const node = useRef(document.createElement("div"));
  useEffect(() => {
    node.current.id = "test";
    document.getElementById("canvas-" + props.name).appendChild(node.current);
  }, []);

  return (
    <>
      <Canvas id={"canvas-" + props.name} frameloop="demand">
        <ContextBridge>
          <Suspense fallback={<Loading message={"Loading Scene..."} />}>
            <OrbitControls
              enableDamping={false}
              ref={orbitControls}
              rotateSpeed={0.5}
            />
            <ViewCube />
            <UpdateCamera />

            {props.inspectionPoints}
            <Environment preset={"night"} background={false} />
            <Model droneView={true} />
            <ambientLight intensity={0.5} />
            <gridHelper args={[20, 20, 0x444444, 0x111111]} />
          </Suspense>
        </ContextBridge>
      </Canvas>
      {props.resetView}
      {props.inspectionPointsLabel}
      {props.buildingOpacity}
    </>
  );

  function UpdateCamera() {
    const dispatch = useDispatch();
    const resetView = useSelector(selectResetView);
    const curTab = useSelector(selectCurrentTab);

    useFrame((state) => {
      if (resetView && curTab === "align") {
        if (resetView === 1) {
          // console.log("Positive X-axis");
          orbitControls.current.target.set(
            1,
            state.camera.position.y,
            state.camera.position.z
          );
        } else if (resetView === 2) {
          // console.log("Negative Y-Axis");
          orbitControls.current.target.set(
            state.camera.position.x,
            -1,
            state.camera.position.z
          );
        } else if (resetView === 3) {
          // console.log("Positive Y-Axis");
          orbitControls.current.target.set(
            state.camera.position.x,
            1,
            state.camera.position.z
          );
        } else if (resetView === 4) {
          // console.log("Negative Z-axis");
          orbitControls.current.target.set(
            state.camera.position.x,
            state.camera.position.y,
            -1
          );
        } else if (resetView === 5) {
          // console.log("Positive Z-axis");
          orbitControls.current.target.set(
            state.camera.position.x,
            state.camera.position.y,
            1
          );
        } else if (resetView === 6) {
          // console.log("Negative X-axis");
          orbitControls.current.target.set(
            -1,
            state.camera.position.y,
            state.camera.position.z
          );
        } else {
          // console.log("Reset view");
          state.camera.position.x = 0;
          state.camera.position.y = 0;
          state.camera.position.z = 5;
          state.camera.updateProjectionMatrix();
          orbitControls.current.target.set(0, 0, 0);
        }

        orbitControls.current.update();
        dispatch(setResetView(0));
      } else if (curTab === "align") {
        dispatch(
          setPos({
            x: Math.round(state.camera.position.x),
            y: Math.round(state.camera.position.y),
            z: Math.round(state.camera.position.z),
          })
        );

        dispatch(
          setRot({
            x: Math.round((state.camera.rotation.x * 180) / Math.PI),
            y: Math.round((state.camera.rotation.y * 180) / Math.PI),
            z: Math.round((state.camera.rotation.z * 180) / Math.PI),
          })
        );
      }
    });
    return null;
  }
};
