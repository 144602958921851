import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  pos: {x: 0, y: 0, z: 0},
  rot: {x: 0, y: 0, z: 0},
  resetView: 0,
};

const cameraDataSlice = createSlice({
  name: 'cameraData',
  initialState,
  reducers: {
    setPos: (state, action) => { state.pos = action.payload; },
    setRot: (state, action) => { state.rot = action.payload; },
    setResetView: (state, action) => {state.resetView = action.payload;},
  }
});

export const selectPos = (state) => state.CameraData.pos;
export const selectRot = (state) => state.CameraData.rot;
export const selectResetView = (state) => state.CameraData.resetView;
export const { setPos, setRot, setResetView } = cameraDataSlice.actions;

export default cameraDataSlice.reducer;