/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
author: Mateus Schwaab (https://sketchfab.com/Mehrus)
license: CC-BY-4.0 (http://creativecommons.org/licenses/by/4.0/)
source: https://sketchfab.com/3d-models/drone-301fdddb9cd9420faa1174e68e0b56ab
title: Drone
*/

import React, { useRef, useState, useMemo } from 'react'
import { useFrame } from '@react-three/fiber';
import { useGLTF } from '@react-three/drei';
import { useSelector } from 'react-redux';
import {
    selectLerpPoint, selectRotation, selectCurrentVelocity,
    selectPotentialPathData
} from '../../reducers/MouseDataReducer'
import * as THREE from 'three'
export default function MouseArrow({ ...props }) {
    const group = useRef()
    const mesh = useRef()
    const velArrow = useRef();
    const position = useSelector(selectLerpPoint);
    const rotation = useSelector(selectRotation);
    const velocity = useSelector(selectCurrentVelocity);
    const { nodes } = useGLTF(process.env.PUBLIC_URL + '/assets/models/drone/scene.gltf')

    useFrame(({ }) => {
        //the lerp is to smooth the rotation/position so its less harsh


        group.current.position.x = THREE.MathUtils.lerp(group.current.position.x,
            position.x, .35);
        group.current.position.y = THREE.MathUtils.lerp(group.current.position.y,
            position.y, .35);
        group.current.position.z = THREE.MathUtils.lerp(group.current.position.z,
            position.z, .35);


        group.current.rotation.y = THREE.MathUtils.lerp(group.current.rotation.y,
            THREE.MathUtils.degToRad(rotation.yaw), .35);
        setPoints([new THREE.Vector3(group.current.position.x
            , group.current.position.y,
            group.current.position.z),
        new THREE.Vector3(velocity.vX + position.x, velocity.vY + position.y, velocity.vZ + position.z)]);
    }, [position, rotation]);

    const [points, setPoints] = useState([]);
    const geometry = useMemo(() => new THREE.BufferGeometry().setFromPoints(points), [points]);


    return (
        <group>
            <group ref={group} {...props} dispose={null} scale={[1, 1, 1]}>
                <group scale={[.25, .25, .25]}>
                    <mesh ref={mesh}
                        geometry={nodes.defaultMaterial.geometry}>
                        <meshBasicMaterial attach="material" color="yellow" transparent={true} opacity={.5} />
                    </mesh>
                </group>
                <group scale={[1, 1, 1]} position={[0, 0, .5]}>
                    <mesh ref={mesh} scale={[1, 1, 1]} rotation={[THREE.MathUtils.degToRad(90), 0, 0]}>
                        <cylinderBufferGeometry attach="geometry" args={[.15, 0, 1]}
                        />
                        <meshBasicMaterial attach="material" color="yellow" transparent={true} opacity={.5} />
                    </mesh>
                </group>

            </group >
            <line ref={velArrow} geometry={geometry}>
                <lineBasicMaterial attach="material" color={'red'} linewidth={1} />
            </line>
        </group>
    )
}
