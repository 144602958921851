import {Canvas, useFrame} from '@react-three/fiber'
import React, {Suspense, useEffect, useMemo, useRef, useState} from "react"
import {Model} from "../model/Model";
import {Loading} from "../misc/Loading";
import {Environment} from "@react-three/drei";
import {useContextBridge} from "@react-three/drei";
import {ReactReduxContext, useSelector} from 'react-redux';
import {selectCurrentTime} from "../../reducers/DroneDataReducer";
import {selectHighlightedPath} from "../../reducers/FlightDataReducer";
import * as THREE from "three";
import { AnimationSlider } from "../animation/AnimationSlider"
import "../../css/Viewer.css";

export const DroneViewer = (props) => {
  const currentTime = Number(useSelector(selectCurrentTime));
  const highlightedPath = useSelector(selectHighlightedPath);
  const [position, setPosition] = useState({ x: 0, y: 0, z: 0 });
  const [rotation, setRotation] = useState({ pitch: 0, roll: 0, yaw: 0 });
  const [fullscreen, setFullscreen] = useState(false);

  useMemo(() => {
    if (!(highlightedPath === undefined || currentTime < 0 || currentTime > highlightedPath.flightAnimationData.totalTime)) {
      setPosition(highlightedPath.flightAnimationData.InterpolatePosition(currentTime));
      setRotation(highlightedPath.flightAnimationData.InterpolateRotation(currentTime));
    }
  }, [currentTime]);


  const ContextBridge = useContextBridge(ReactReduxContext);
  // const node = useRef(document.createElement('div'));
  // useEffect(() => {
  //   node.current.id = 'test';
  //   document.getElementById("canvas-" + props.name).appendChild(node.current);
  // }, []);

  return (
    <>
    <div className = "front">
    <Canvas style={{zIndex:'999'}} className={fullscreen ? "bigViewer" : "smallViewer"} >
      <ContextBridge>
        <Suspense fallback={<Loading message={"Loading Scene..."}/>}>
          {props.inspectionPoints}
          <Environment preset={"night"} background={false}/>
          <Model droneView/>
          <ambientLight intensity={0.5}/>
        </Suspense>
      </ContextBridge>
      <AnimateDroneCamera/>
    </Canvas>
    </div>
    <AnimationSlider stateFullscreen={fullscreen} fullscreen={setFullscreen} />

    </>
  );

  /**
   * @return {null}
   */
  function AnimateDroneCamera() {
    useFrame(({camera}) => {
      camera.position.x = position.x;
      camera.position.y = position.y;
      camera.position.z = position.z;
      camera.rotation.y = THREE.MathUtils.degToRad(rotation.yaw+180);
    });
    return null
  }
};
