import {createPortal, useFrame, useThree} from "@react-three/fiber";
import {useMemo, useRef, useState} from "react";
import {Matrix4, Scene, CanvasTexture} from "three";
import {OrthographicCamera, useCamera} from "@react-three/drei";
import React from "react";
import {useDispatch} from "react-redux";
import {setResetView} from "../../reducers/CameraDataReducer";
import { Container } from "react-bootstrap";

export const ViewCube = () => {
  const { gl, scene, camera, size } = useThree();
  const virtualScene = useMemo(() => new Scene(), []);
  const virtualCam = useRef();
  const ref = useRef();
  const [hover, set] = useState(null);
  const matrix = new Matrix4();
  const dispatch = useDispatch();

  useFrame(() => {
    matrix.copy(camera.matrix).invert();
    ref.current.quaternion.setFromRotationMatrix(matrix);
    gl.autoClear = true;
    gl.render(scene, camera);
    gl.autoClear = false;
    gl.clearDepth();
    gl.render(virtualScene, virtualCam.current)
  }, 1);

  const sideIdentify = (index) => {
    if(index === 0) return <meshLambertMaterial attachArray="material" map={createTextTexture("RIGHT", 'black', 'white')} key={index} color={hover === index ? 'hotpink' : 'yellow'} />
    if(index === 1) return <meshLambertMaterial attachArray="material" map={createTextTexture("LEFT", 'black', 'white')} key={index} color={hover === index ? 'hotpink' : 'yellow'} />
    if(index === 2) return <meshLambertMaterial attachArray="material" map={createTextTexture("TOP", 'black', 'white')} key={index} color={hover === index ? 'hotpink' : 'yellow'} />
    if(index === 3) return <meshLambertMaterial attachArray="material" map={createTextTexture("BOTTOM", 'black', 'white', true)} key={index} color={hover === index ? 'hotpink' : 'yellow'} />
    if(index === 4) return <meshLambertMaterial attachArray="material" map={createTextTexture("FRONT", 'black', 'white')} key={index} color={hover === index ? 'hotpink' : 'yellow'} />
    if(index === 5) return <meshLambertMaterial attachArray="material" map={createTextTexture("BACK", 'black', 'white')} key={index} color={hover === index ? 'hotpink' : 'yellow'} />
  }
  const calculateTextureSize = (approx) => {
    return Math.max(128, Math.pow(2, Math.floor(Math.log(approx) / Math.log(2))))
  }
  const createTextTexture = (text, color, backColor, bottom) => {
    const size = 48
    const textMargin = 1.5
  
    const canvas = document.createElement('canvas')
    const context = canvas.getContext('2d')
    const ts = calculateTextureSize(size / 2 + size * textMargin) * 2
    canvas.width = canvas.height = ts
    if(!bottom) context.font = 'bold ' + ((ts / (2 * textMargin))-30) + 'pt Arial'
    else context.font = 'bold ' + ((ts / (2 * textMargin))-45) + 'pt Arial'
    context.fillStyle = backColor
    context.fillRect(0, 0, canvas.width, canvas.height)
    context.textAlign = 'center'
    context.textBaseline = 'middle'
    context.fillStyle = color
    context.fillText(text, canvas.width / 2, canvas.height / 2)
  
    const texture = new CanvasTexture(canvas)
    return texture
  }

  return createPortal(
    <>
      <OrthographicCamera ref={virtualCam} makeDefault={false} position={[0, 0, 100]} />
      <mesh
        ref={ref}
        raycast={useCamera(virtualCam)}
        position={[-(size.width / 2-40), size.height / 2 - 40, 0]}
        onPointerOut={(e) => set(null)}
        onPointerMove={(e) => set(Math.floor(e.faceIndex / 2))}
        onClick={() => dispatch(setResetView(hover !== 0 ? hover : 6))}
      >
        {[...Array(6)].map((_, index) => (
          sideIdentify(index)
        ))}
        <boxGeometry args={[48, 48, 48]} />
      </mesh>
      <ambientLight intensity={0.5} />
      <pointLight position={[-10, 10, 10]} intensity={0.5} />
    </>,
    virtualScene
  )
};