import React from 'react'
import {Container, Row, Col, Form} from 'react-bootstrap'
import Button from 'react-bootstrap/Button'
import { reset, selectCoordinates, selectRotation, selectScale, setDefault, updateVar } from '../../reducers/BuildingDataReducer'
import { useDispatch, useSelector } from 'react-redux'
import '../../css/AlignSidebar.css'

export const DefaultAlignment = () => {
    const dispatch = useDispatch()
    return(
        <Container fluid className='flex-center'>
            <Button variant="primary" onClick={() => dispatch(setDefault())}> Default </Button>
        </Container>
    );
}

export const BuildingTranslate = (props) => {
    const dispatch = useDispatch()
    const coordinates = useSelector(selectCoordinates)
    const delta = 0.1
    
    const handleChange = (e, varChar) => {
        let cursor = document.getSelection()
        // console.log(cursor)
        let input = e.currentTarget.textContent
        let subInput = input.indexOf(".")

        if(subInput !== 0){
            let num = parseFloat(input);
            if(num!=null && !isNaN(num)){
                dispatch(updateVar({varName: varChar, amount: num, funct: true}))
            }
        }
    
    } 
    
    return(
        <Container fluid className='flex-center'>
            <Row className='flex-center'>
                <Col sm={2}> 
                    <Button variant="primary" onClick={() => dispatch(updateVar({varName: 'x', amount: -delta}))}>-</Button>
                </Col>
                <Col sm={4} className='center-text'>
                    <div>X:&ensp;
                        <span contentEditable="true" suppressContentEditableWarning="true" onInput={(e) => handleChange(e, "x")}>
                            {coordinates.x}
                        </span>
                    </div>
                </Col>
                <Col sm={2}>
                    <Button variant="primary" onClick={() => dispatch(updateVar({varName: 'x', amount: delta}))}>+</Button>
                </Col>
                <Col sm={4}>
                    <Button variant="outline-warning" onClick={() => dispatch(reset('x'))}>Reset</Button>
                </Col>
                <Form.Range
                    value={coordinates.x}
                    onChange={e => dispatch(updateVar({varName: 'x', amount: (e.target.value-coordinates.x)}))}
                    min = {-20}
                    max = {20}
                    step = {.1}
                />
            </Row>
            <Row className='flex-center'>
                <Col sm={2}>
                    <Button variant="primary" className="control__btnY-" onClick={() => dispatch(updateVar({varName: 'y', amount: -delta}))}>-</Button>
                </Col>
                <Col sm={4} className='center-text'>
                    <div>Y:&ensp;
                        <span contentEditable="true" suppressContentEditableWarning="true" onInput={(e) => handleChange(e, "y")}>
                            {coordinates.y}
                        </span>
                    </div>
                </Col>
                <Col sm={2}>
                    <Button variant="primary" className="control__btnY+" onClick={() => dispatch(updateVar({varName: 'y', amount: delta}))}>+</Button>
                </Col>
                <Col sm={4}>
                    <Button variant="outline-warning" className="resetY" onClick={() => dispatch(reset('y'))}>Reset</Button>
                </Col>
                <Form.Range
                    value={coordinates.y}
                    onChange={e => dispatch(updateVar({varName: 'y', amount: (e.target.value-coordinates.y)}))}
                    min = {-20}
                    max = {20}
                    step = {.1}
                />
            </Row>
            <Row className='flex-center'>
                <Col sm={2}>
                    <Button variant="primary" className="control__btnZ-" onClick={() => dispatch(updateVar({varName: 'z', amount: -delta}))}>-</Button>
                </Col>
                <Col sm={4} className='center-text'>
                    <div>Z:&ensp;
                        <span contentEditable="true" suppressContentEditableWarning="true" onInput={(e) => handleChange(e, "z")}>
                            {coordinates.z}
                        </span>
                    </div>
                </Col>
                <Col sm={2}>
                    <Button variant="primary" className="control__btnZ+" onClick={() => dispatch(updateVar({varName: 'z', amount: delta}))}>+</Button>
                </Col>
                <Col sm={4}>
                    <Button variant="outline-warning" className="resetZ" onClick={() => dispatch(reset('z'))}>Reset</Button>
                </Col>
                <Form.Range
                    value={coordinates.z}
                    onChange={e => dispatch(updateVar({varName: 'z', amount: (e.target.value-coordinates.z)}))}
                    min = {-20}
                    max = {20}
                    step = {.1}
                />
            </Row>
      </Container>
    );
}

export const BuildingRotate = (props) => {
    const dispatch = useDispatch()
    const rotation = useSelector(selectRotation)
    const delta = 1 
    
    const handleChange = (e, varChar) => {
        if(e.currentTarget.textContent.charAt(0) !== '.'){
            let num = parseFloat(e.currentTarget.textContent);
            if(num!=null && !isNaN(num)){
                dispatch(updateVar({varName: varChar, amount: num, funct: true}))
            }
        }
    
    } 
     
    return(
        <Container className='flex-center'>
            <Row className='flex-center'>
                <Col sm={2}>
                    <Button variant="primary" className="control__btnPitch-" onClick={() => dispatch(updateVar({varName: 'pitch', amount: -delta}))}>-</Button>
                </Col>
                <Col sm={4} className='center-text'>
                    <div>Pitch:&ensp;
                        <span contentEditable="true" suppressContentEditableWarning="true" onInput={(e) => handleChange(e, "pitch")}>
                            {rotation.pitch}
                        </span>
                    </div>
                </Col>
                <Col sm={2}>
                    <Button variant="primary" className="control__btnPitch+" onClick={() => dispatch(updateVar({varName: 'pitch', amount: delta}))}>+</Button>
                </Col>
                <Col sm={4}>
                    <Button variant="outline-warning" className="resetPitch" onClick={() => dispatch(reset('pitch'))}>Reset</Button>
                </Col>
                <Form.Range
                    value={rotation.pitch}
                    onChange={e => dispatch(updateVar({varName: 'pitch', amount: (e.target.value-rotation.pitch)}))}
                    min = {-360}
                    max = {360}
                    step = {1}
                />
            </Row>
            <Row className='flex-center'>
                <Col sm={2}>
                    <Button variant="primary" className="control__btnYaw-" onClick={() => dispatch(updateVar({varName: 'yaw', amount: -delta}))}>-</Button>
                </Col>
                <Col sm={4} className='center-text'>
                    <div>Yaw:&ensp;
                        <span contentEditable="true" suppressContentEditableWarning="true" onInput={(e) => handleChange(e, "yaw")}>
                            {rotation.yaw}
                        </span>
                    </div>
                </Col>
                <Col sm={2}>
                    <Button variant="primary" className="control__btnYaw+" onClick={() => dispatch(updateVar({varName: 'yaw', amount: delta}))}>+</Button>
                </Col>
                <Col sm={4}>
                    <Button variant="outline-warning" className="resetYaw" onClick={() => dispatch(reset('yaw'))}>Reset</Button>
                </Col>
                <Form.Range
                    value={rotation.yaw}
                    onChange={e => dispatch(updateVar({varName: 'yaw', amount: (e.target.value-rotation.yaw)}))}
                    min = {-360}
                    max = {360}
                    step = {1}
                />
            </Row>
            <Row className='flex-center'>
                <Col sm={2}>
                    <Button variant="primary" className="control__btnRoll-" onClick={() => dispatch(updateVar({varName: 'roll', amount: -delta}))}>-</Button>
                </Col>
                <Col sm={4} className='center-text'>
                    <div>Roll:&ensp;
                        <span contentEditable="true" suppressContentEditableWarning="true" onInput={(e) => handleChange(e, "roll")}>
                            {rotation.roll}
                        </span>
                    </div>
                </Col>
                <Col sm={2}>
                    <Button variant="primary" className="control__btnRoll+" onClick={() => dispatch(updateVar({varName: 'roll', amount: delta}))}>+</Button>
                </Col>
                <Col sm={4}>
                    <Button variant="outline-warning" className="resetRoll" onClick={() => dispatch(reset('roll'))}>Reset</Button>
                </Col>
                <Form.Range
                    value={rotation.roll}
                    onChange={e => dispatch(updateVar({varName: 'roll', amount: (e.target.value-rotation.roll)}))}
                    min = {-360}
                    max = {360}
                    step = {1}
                />
            </Row>
      </Container>
    );
}

export const BuildingScale = (props) => {
    const dispatch = useDispatch()
    const scale = useSelector(selectScale)
    const delta = .01
    
    const handleChange = (e, varChar) => {
        if(e.currentTarget.textContent.charAt(0) !== '.'){
            let num = parseFloat(e.currentTarget.textContent);
            if(num!=null && !isNaN(num)){
                dispatch(updateVar({varName: varChar, amount: num, funct: true}))
            }
        }
    
    } 
    
    return(
        <Container className='flex-center'>
            <Row className='flex-center'>
            <Col sm={2}>
                    <Button variant="primary" className="control__btnScale-" onClick={() => dispatch(updateVar({varName: 'scale', amount: -delta}))}>-</Button>
                </Col>
                <Col sm={4}>
                    <div>Scale:&ensp;
                        <span contentEditable="true" suppressContentEditableWarning="true" onInput={(e) => handleChange(e, "scale")}>
                            {scale}
                        </span>
                    </div>
                </Col>
                <Col sm={2}>
                    <Button variant="primary" className="control__btnScale+" onClick={() => dispatch(updateVar({varName: 'scale', amount: delta}))}>+</Button>
                </Col>
                <Col>
                    <Button variant="outline-warning" className="resetScale" onClick={() => dispatch(reset('scale'))}>Reset</Button>
                </Col>
                <Form.Range
                    value={scale}
                    onChange={e => dispatch(updateVar({varName: 'scale', amount: (e.target.value-scale)}))}
                    min = {-2}
                    max = {2}
                    step = {delta}
                />
            </Row>
        </Container>
    );
}
