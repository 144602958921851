import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    currentTab: 'upload'
}

const tabDataSlice = createSlice({
    name: 'tabData',
    initialState,
    reducers: {
        setCurrentTab: (state, action) => {state.currentTab = action.payload}
    }
});

export const selectCurrentTab = (state) => state.TabData.currentTab;
export const { setCurrentTab } = tabDataSlice.actions;

export default tabDataSlice.reducer;