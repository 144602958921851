import React from 'react';
import {Accordion, Container, Popover, OverlayTrigger, Button} from 'react-bootstrap';
import {XCircleFill} from "react-bootstrap-icons";
import uuid from 'react-uuid'
import '../../css/Sidebar.css'
import '../../css/Accordion.css'
import 'simplebar';
import 'simplebar/dist/simplebar.min.css';

export const Sidebar = (props) => {
  let index = 0
  return (
    // <div className="scrollbar-height col-auto col-md-4 col-xl-3 px-sm-2 px-0 bg-dark z1 noPad">
      <div data-simplebar data-simplebar-auto-hide="false" className="scrollbar-height  col-auto col-md-4 col-xl-3 px-sm-2 px-0 bg-dark z1 noPad">
      <div className="d-flex flex-column align-items-center align-items-sm-start pt-2 text-white min-vh-100">
        <Container className="scrollbar-height sidebar-container">
          <Accordion defaultActiveKey={props.defaultActiveKeys} alwaysOpen>
            {props.children.map(child =>
              <Accordion.Item eventKey={String(index++)} key={uuid()}>
                <div style={{
                  position: "absolute",
                  zIndex: 4,
                  right: 60,
                  marginTop: 10,
                }}>
                  <OverlayTrigger
                    trigger="click"
                    rootClose
                    key={child.props.name}
                    placement="right"
                    overlay={
                      <Popover>
                        <Popover.Header as="h3" style={{height: 25}}>
                          <XCircleFill
                            size={20}
                            style={{
                              position: "absolute",
                              right: 5,
                              top: 3
                            }}
                            onClick={() => document.body.click()}
                          />
                        </Popover.Header>
                        <Popover.Body style={{
                          paddingBottom: 0,
                          paddingTop: 10
                        }}>
                          {child.props.info}
                        </Popover.Body>
                      </Popover>
                    }
                  >
                    <Button className="rounded-circle" variant="outline-secondary" size="sm">&nbsp;<strong>?</strong>&nbsp;</Button>
                  </OverlayTrigger>
                </div>
                <Accordion.Header>{child.props.name ? child.props.name : "Default"}</Accordion.Header>
                <Accordion.Body>{child}</Accordion.Body>
              </Accordion.Item>
            )}
          </Accordion>
        </Container>
      </div>
    </div>
    // </div>
    
  );
};

Sidebar.defaultProps = {
  defaultActiveKeys: []
}