import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    coordinates: { x: -2.1, y: 1.7, z: 0.3 },
    rotation: { pitch: 0, yaw: -79, roll: 0 },
    scale: 0.97,
    transparent:false,
    opacity:1
}

function roundToTwo(num) {    
    return +(Math.round(num + "e+2")  + "e-2");
}

const buildingDataSlice = createSlice({
    name: 'buildingData',
    initialState,
    reducers: { 
        setDefault: (state) =>{
            state.coordinates.x = -2.1
            state.coordinates.y = 1.7
            state.coordinates.z = 0.3
            state.rotation.yaw = -79
            state.scale = 0.97
        },

        updateOpacity: (state, action) => {
            state.transparent = action.payload < 1;
            state.opacity = action.payload;
        },

        updateVar: (state, action) => {
            const{
                varName,
                amount,
                funct=false
            } = action.payload;
            if(funct === false){
                if(varName === 'x'){
                    state.coordinates.x += amount
                    state.coordinates.x = roundToTwo(state.coordinates.x)
                }else if(varName === 'y'){
                    state.coordinates.y += amount
                    state.coordinates.y = roundToTwo(state.coordinates.y)
                }else if(varName === 'z'){
                    state.coordinates.z += amount
                    state.coordinates.z = roundToTwo(state.coordinates.z)
                }else if(varName === 'pitch'){
                    state.rotation.pitch += amount
                    state.rotation.pitch = roundToTwo(state.rotation.pitch)
                }else if(varName === 'yaw'){
                    state.rotation.yaw += amount
                    state.rotation.yaw = roundToTwo(state.rotation.yaw)
                }else if(varName === 'roll'){
                    state.rotation.roll += amount
                    state.rotation.roll = roundToTwo(state.rotation.roll)
                }else if(varName === 'scale'){
                    state.scale += amount
                    state.scale = roundToTwo(state.scale)
                }
            }
            if(funct === true){
                if(varName === 'x'){
                    state.coordinates.x = roundToTwo(amount)
                }else if(varName === 'y'){
                    state.coordinates.y = roundToTwo(amount)
                }else if(varName === 'z'){
                    state.coordinates.z = roundToTwo(amount)
                }else if(varName === 'pitch'){
                    state.rotation.pitch = roundToTwo(amount)
                }else if(varName === 'yaw'){
                    state.rotation.yaw = roundToTwo(amount)
                }else if(varName === 'roll'){
                    state.rotation.roll = roundToTwo(amount)
                }else if(varName === 'scale'){
                    state.scale = roundToTwo(amount)
                }
            }
        },

        reset: (state, action) => {
            if(action.payload === 'x'){
                state.coordinates.x = 0
            }else if(action.payload === 'y'){
                state.coordinates.y = 0
            }else if(action.payload === 'z'){
                state.coordinates.z = 0
            }else if(action.payload === 'pitch'){
                state.rotation.pitch = 0
            }else if(action.payload === 'yaw'){
                state.rotation.yaw = 0
            }else if(action.payload === 'roll'){
                state.rotation.roll = 0
            }else if(action.payload === 'scale'){
                state.scale = 1
            }
        }
    }
});

export const selectCoordinates = (state) => state.BuildingData.coordinates;
export const selectRotation = (state) => state.BuildingData.rotation;
export const selectScale = (state) => state.BuildingData.scale;
export const selectTransparent = (state) => state.BuildingData.transparent;
export const selectOpacity = (state) => state.BuildingData.opacity;
export const { updateVar, reset, setDefault, updateOpacity } = buildingDataSlice.actions;

export default buildingDataSlice.reducer;