import React, { useState } from 'react';
import { Button, Form } from "react-bootstrap";
import { useDispatch } from 'react-redux';
import { setCurrentTab } from '../../reducers/TabDataReducer';

export const UploadForm = (props) => {

    const [validated, setValidated] = useState(false);
    const [useDefault, setUseDefault] = useState(true);
    const dispatch = useDispatch();

    const setDefault = (value) => {
        setUseDefault(value);
    };
    const handleSubmit = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
            event.stopPropagation();
        }
        setValidated(true);
        dispatch(setCurrentTab("align"));
    };

    return(
        <>
            <div className="wrapper">
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <fieldset>
                        <Form.Group className="mb-3">
                            <h5>
                            <Form.Check
                                type="checkbox"
                                id="default"
                                label="Use default files"
                                checked={useDefault}
                                required={useDefault}
                                disabled={true}     // remove this line when adding option to upload files
                                onChange={(e) => setDefault(e.currentTarget.checked)}
                            /></h5>
                        </Form.Group>
                        <p>
                            <strong>Building Scene</strong>
                            <br/>Drone_Space_Low_Res.glb
                        </p>
                        <p>
                            <strong>Flight Paths</strong>
                            <br/>Subject_1_Run_1.json
                            <br/>Subject_1_Run_2.json
                            <br/>Subject_2_Run_1.json
                            <br/>Subject_2_Run_2.json
                            <br/>Subject_3_Run_1.json
                            <br/>Subject_3_Run_2.json
                            <br/>Subject_4_Run_1.json
                            <br/>Subject_4_Run_2.json
                        </p>
                        <p>
                            <strong>Inspection Points</strong>
                            <br/>Marker_Data.json
                        </p>
                        {/*<Button variant="light" type="submit">{"Continue to Align >>"}</Button>*/}
                        {/*<br/><br/><hr/>*/}
                        {/*<h5>Upload (WIP)</h5>*/}
                        {/*<Form.Group controlId="building" className="mb-3">*/}
                        {/*    <Form.Label>Building Scene</Form.Label>*/}
                        {/*    <Form.Control*/}
                        {/*        type="file"*/}
                        {/*        disabled={useDefault}*/}
                        {/*        required={!useDefault}*/}
                        {/*    />*/}
                        {/*</Form.Group>*/}
                        {/*<Form.Group controlId="paths" className="mb-3">*/}
                        {/*    <Form.Label>Flight Paths</Form.Label>*/}
                        {/*    <Form.Control*/}
                        {/*        type="file"*/}
                        {/*        disabled={useDefault}*/}
                        {/*        required={!useDefault}*/}
                        {/*        multiple*/}
                        {/*    />*/}
                        {/*</Form.Group>*/}
                        {/*<Form.Group controlId="inspection" className="mb-3">*/}
                        {/*    <Form.Label>Inspection Points</Form.Label>*/}
                        {/*    <Form.Control*/}
                        {/*        type="file"*/}
                        {/*        disabled={useDefault}*/}
                        {/*        required={!useDefault}*/}
                        {/*    />*/}
                        {/*</Form.Group>*/}
                    </fieldset>
                </Form>
            </div>
        </>
    )
}