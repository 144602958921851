import React, { useState, useRef } from "react";
import { useEffect } from "react";
import { Form, Row, Col, Button, Badge, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  setCurrentTime,
  selectCurrentTime,
} from "../../reducers/DroneDataReducer";
import { selectHighlightedPath } from "../../reducers/FlightDataReducer";
import { invalidate } from "@react-three/fiber";
import {
  ArrowsFullscreen,
  PlayFill,
  Pause,
  FullscreenExit,
} from "react-bootstrap-icons";
import "../../css/Modal.css";

export const AnimationSlider = (props) => {
  const highligtedPath = useSelector(selectHighlightedPath);
  const maxTime =
    highligtedPath === undefined
      ? 0
      : highligtedPath.flightAnimationData.totalTime;
  const dispatch = useDispatch();
  const currentTime = useSelector(selectCurrentTime);

  const [playing, setPlaying] = useState(false);
  const [frameRate] = useState(60);
  const [speed, setSpeed] = useState(1);
  const frame = useRef(0);
  const animate = () => {
    var newTime = currentTime + speed / frameRate;
    if (newTime > maxTime) newTime = 0;
    invalidate();
    dispatch(setCurrentTime(newTime));
    frame.current = requestAnimationFrame(animate);
  };

  useEffect(() => {
    // start or continue animation in case of shouldAnimate if true
    if (playing) {
      frame.current = requestAnimationFrame(animate);
    } else {
      // stop animation
      cancelAnimationFrame(frame.current);
    }
    return () => cancelAnimationFrame(frame.current);
  });

  const expandCanvas = () => {
    props.fullscreen(true);
  };

  const minimizeCanvas = () => {
    props.fullscreen(false);
  };
  return (
    <>
      {props.stateFullscreen ? <div id="cover"></div> : <div></div>}

      <div
        className={
          props.stateFullscreen
            ? "largeControls largePosition"
            : "smallControls smallPosition"
        }
      >
        <Form>
          <Form.Group as={Row}>
            <Form.Range
              className={
                props.stateFullscreen ? "largeTime time" : "time smallTime"
              }
              value={currentTime}
              onChange={(e) => {
                invalidate();
                dispatch(setCurrentTime(e.target.value));
              }}
              min={0}
              max={maxTime}
              step={1}
            />
          </Form.Group>

          <Form.Group as={Row}>
            <Col className="flexy" xs={props.stateFullscreen ? "1" : "2"}>
              <Button
                variant="outline-light"
                onClick={() => setPlaying(!playing)}
              >
                {playing ? <Pause size={25} /> : <PlayFill size={25} />}
              </Button>
            </Col>
            <Col className="flexy" xs={props.stateFullscreen ? "6" : "3"}>
              <Badge bg="dark">{currentTime.toFixed(1) + " sec"}</Badge>
            </Col>

            {props.stateFullscreen ? (
              <>
                <Col xs="3" className="flexy">
                  <Form.Range
                    className={props.stateFullscreen ? "rangeMarginRight" : ""}
                    value={speed}
                    onChange={(e) => {
                      invalidate();
                      setSpeed(e.target.value);
                    }}
                    min={1}
                    max={20}
                    step={1}
                  />
                  <Badge>{speed + "x speed"}</Badge>
                </Col>
              </>
            ) : (
              <>
                <Col xs="5" className="flexy">
                  <Form.Range
                    className={
                      props.stateFullscreen
                        ? "rangeMarginRight"
                        : "rangeSmallMarginRight"
                    }
                    value={speed}
                    onChange={(e) => {
                      invalidate();
                      setSpeed(e.target.value);
                    }}
                    min={1}
                    max={20}
                    step={1}
                  />
                  <Badge>{speed + "x"}</Badge>
                </Col>
              </>
            )}

            <Col className="flexy2" xs={props.stateFullscreen ? "2" : "2"}>
              {props.stateFullscreen ? (
                <Button variant="outline-light" onClick={minimizeCanvas}>
                  <FullscreenExit size={25} />
                </Button>
              ) : (
                <Button variant="outline-light" onClick={expandCanvas}>
                  <ArrowsFullscreen size={25} />
                </Button>
              )}
            </Col>
          </Form.Group>
        </Form>
      </div>
    </>
  );
};
