import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Badge, Alert } from "react-bootstrap";
import { selectCurrentTime } from "../../reducers/DroneDataReducer";
import {
  selectHighlightedPath,
  selectHighlightedPathName,
} from "../../reducers/FlightDataReducer";
import { paths } from "../paths/FlightPaths";
import "../../css/DroneData.css";

export const DroneData = () => {
  const currentTime = Number(useSelector(selectCurrentTime));
  const highlightedPath = useSelector(selectHighlightedPath);
  const highlightedPathName = useSelector(selectHighlightedPathName);
  const [position, setPosition] = useState({ x: 0, y: 0, z: 0 });
  const [rotation, setRotation] = useState({ pitch: 0, roll: 0, yaw: 0 });
  const [speed, setSpeed] = useState(0);
  const [velocity, setVelocity] = useState({ vX: 0, vY: 0, vZ: 0 });
  const highlightedPathColor = highlightedPathName
    ? paths.find((path) => path.name === highlightedPathName).color
    : "white";

  useMemo(() => {
    if (
      !(
        highlightedPath === undefined ||
        currentTime < 0 ||
        currentTime > highlightedPath.flightAnimationData.totalTime
      )
    ) {
      setPosition(
        highlightedPath.flightAnimationData.InterpolatePosition(currentTime)
      );
      setRotation(
        highlightedPath.flightAnimationData.InterpolateRotation(currentTime)
      );
      setVelocity(
        highlightedPath.flightAnimationData.InterpolateVelocity(currentTime)
      );
      setSpeed(
        highlightedPath.flightAnimationData.InterpolateSpeed(currentTime)
      );
    }
  }, [currentTime]);

  return (
    <div className="drone-data">
      <p>
        <b>Flight Path: </b>
        <span style={{ color: highlightedPathColor }}>
          {highlightedPathName}
        </span>
      </p>
      <p>
        <b>Location (m): </b>
      </p>
      <p>
        &emsp; (&nbsp;x:&nbsp;&nbsp;
        <Badge pill bg="warning" text="dark">
          {position.x.toFixed(1)}
        </Badge>
        &nbsp;,&nbsp;&nbsp;y:&nbsp;&nbsp;
        <Badge pill bg="warning" text="dark">
          {position.y.toFixed(1)}
        </Badge>
        &nbsp;,&nbsp;&nbsp;z:&nbsp;&nbsp;
        <Badge pill bg="warning" text="dark">
          {position.z.toFixed(1)}
        </Badge>
        &nbsp;)
      </p>
      <p>
        <b>Rotation (deg):</b>
      </p>
      <p>
        &emsp; (&nbsp;Pitch:&nbsp;&nbsp;
        <Badge pill bg="warning" text="dark">
          {Math.round(rotation.pitch)}&deg;
        </Badge>
        &nbsp;,&nbsp;&nbsp;Yaw:&nbsp;&nbsp;
        <Badge pill bg="warning" text="dark">
          {Math.round(rotation.yaw)}&deg;
        </Badge>
        &nbsp;,&nbsp;&nbsp;Roll:&nbsp;&nbsp;
        <Badge pill bg="warning" text="dark">
          {Math.round(rotation.roll)}&deg;
        </Badge>
        &nbsp;)
      </p>
      <p>
        <b>Velocity (m/s):</b>
      </p>
      <p>
        &emsp; (&nbsp;x:&nbsp;&nbsp;
        <Badge pill bg="warning" text="dark">
          {velocity.vX.toFixed(1)}
        </Badge>
        &nbsp;,&nbsp;&nbsp;y:&nbsp;&nbsp;
        <Badge pill bg="warning" text="dark">
          {velocity.vY.toFixed(1)}
        </Badge>
        &nbsp;,&nbsp;&nbsp;z:&nbsp;&nbsp;
        <Badge pill bg="warning" text="dark">
          {velocity.vZ.toFixed(1)}
        </Badge>
        &nbsp;)
      </p>
      <p>
        {speed < 0.025 && (
          <Badge pill bg="danger" className="md-2">
            Difficult!
          </Badge>
        )}
        <b>Speed (m/s):</b> {speed.toFixed(3)}
      </p>
      <p>
        <b>Time (s):</b> {currentTime.toFixed(1)}
      </p>
    </div>
  );
};
