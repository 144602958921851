import React from 'react';
import "../../css/Youtube.css";

export const TutorialVideo = (props) => {
    let link = "https://www.youtube.com/embed/" + props.embedId;
  return (
      <div>
          <h2>{props.videoHeading}</h2>
    <div className="video-responsive">
      
      <iframe
        width="853"
        height="480"
        src={link}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
      />
    </div>
    <p>{props.videoDescription}</p>
      </div>
    
  )
  
}