import React, { useRef, useEffect } from "react";
import * as THREE from "three";
import { useSelector, useDispatch } from "react-redux";
import {
  updateInspectionData,
  updateSelectedIndex,
  selectInspectionPoints,
  selectInspectionLength,
  selectLabeled,
} from "../../reducers/InspectionDataReducer";
import { vec4 } from "three/examples/jsm/renderers/nodes/ShaderNode";

/*  Reference: https://codesandbox.io/s/github/onion2k/r3f-by-example/tree/develop/examples/geometry/instanced-cubes?file=/src/index.js:1179-1195
    Instanced Mesh: https://threejs.org/docs/index.html?q=instan#api/en/objects/InstancedMesh
        - used for rendering a large number of objects with the same geometry and material but with different world transformations
        - improves rendering performance by reducing number of drawing calls
 */

export const InspectionPoints = (props) => {
  const tempBoxes = new THREE.Object3D();
  const dispatch = useDispatch();
  const points = useSelector(selectInspectionPoints);
  const length = useSelector(selectInspectionLength);
  const labeled = useSelector(selectLabeled);

  function HandleClick(e) {
    const { instanceId, button } = e;
    // console.log(e);
    dispatch(updateSelectedIndex(instanceId));
  }

  const materialDefault = new THREE.MeshLambertMaterial({ color: "red" });

  const material = [
    new THREE.MeshStandardMaterial({
      map: new THREE.TextureLoader().load(
        "https://threejs.org/examples/textures/square-outline-textured.png"
      ),
    }),
    new THREE.MeshStandardMaterial({
      map: new THREE.TextureLoader().load(
        "https://threejs.org/examples/textures/roughness_map.jpg"
      ),
    }),
    new THREE.MeshStandardMaterial({
      map: new THREE.TextureLoader().load(
        "https://threejs.org/examples/textures/tri_pattern.jpg"
      ),
    }),
    new THREE.MeshStandardMaterial({
      map: new THREE.TextureLoader().load(
        "https://threejs.org/examples/textures/water.jpg"
      ),
    }),
    new THREE.MeshStandardMaterial({
      map: new THREE.TextureLoader().load(
        "https://threejs.org/examples/textures/water.jpg"
      ),
    }),
    new THREE.MeshStandardMaterial({
      map: new THREE.TextureLoader().load(
        "https://threejs.org/examples/textures/water.jpg"
      ),
    }),
  ];

  // Create texture Matrix for alphabet
  var textureMatrix = [];
  for (let i = 65; i < 78; i++) {
    const text = String.fromCharCode(i);
    var bitmap = document.createElement("canvas");
    var g = bitmap.getContext("2d");
    bitmap.width = 40;
    bitmap.height = 40;
    g.font = "Bold 50px Arial";
    g.textBaseline = "middle";
    g.textAlign = "center";

    g.fillStyle = "white";
    g.fillText(text, 20, 25);
    g.strokeStyle = "red";
    g.strokeText(text, 20, 25);

    var texture = new THREE.Texture(bitmap);
    texture.needsUpdate = true;
    textureMatrix[i - 65] = texture;
  }

  material.forEach((m, side) => {
    m.onBeforeCompile = (shader) => {
      shader.uniforms.textures = { type: "tv", value: textureMatrix };

      shader.vertexShader = shader.vertexShader
        .replace(
          "#define STANDARD",
          `#define STANDARD
                        varying vec3 vTint;
                        varying float vTextureIndex;`
        )
        .replace(
          "#include <common>",
          `#include <common>
                    attribute vec3 tint;
                    attribute float textureIndex;`
        )
        .replace(
          "#include <project_vertex>",
          `#include <project_vertex>
                    vTint = tint;
                    vTextureIndex=textureIndex;`
        );

      shader.fragmentShader = shader.fragmentShader
        .replace(
          "#define STANDARD",
          `#define STANDARD
                        uniform sampler2D textures[14];
                        varying vec3 vTint;
                        varying float vTextureIndex;`
        )
        .replace(
          "#include <fog_fragment>",
          `#include <fog_fragment>
                    float x = vTextureIndex;
                    vec4 col;
                    col = texture2D(textures[0], vUv ) * step(-0.1, x) * step(x, 0.1);
                    col += texture2D(textures[1], vUv ) * step(0.9, x) * step(x, 1.1);
                    col += texture2D(textures[2], vUv ) * step(1.9, x) * step(x, 2.1);
                    col += texture2D(textures[3], vUv ) * step(2.9, x) * step(x, 3.1);
                    col += texture2D(textures[4], vUv ) * step(3.9, x) * step(x, 4.1);
                    col += texture2D(textures[5], vUv ) * step(4.9, x) * step(x, 5.1);
                    col += texture2D(textures[6], vUv ) * step(5.9, x) * step(x, 6.1);
                    col += texture2D(textures[7], vUv ) * step(6.9, x) * step(x, 7.1);
                    col += texture2D(textures[8], vUv ) * step(7.9, x) * step(x, 8.1);
                    col += texture2D(textures[9], vUv ) * step(8.9, x) * step(x, 9.1);
                    col += texture2D(textures[10], vUv ) * step(9.9, x) * step(x, 10.1);
                    col += texture2D(textures[11], vUv ) * step(10.9, x) * step(x, 11.1);
                    col += texture2D(textures[12], vUv ) * step(11.9, x) * step(x, 12.1);
                    col += texture2D(textures[13], vUv ) * step(12.9, x) * step(x, 13.1);
                    gl_FragColor = col;
                    `
        );
    };
  });
  const textures = [];
  const boxesGeometry = new THREE.BoxBufferGeometry(0.15, 0.15, 0.15);
  const ref = useRef();

  useEffect(() => {
    dispatch(updateInspectionData({ inspectionFile: props.inspectionFile }));
  }, []);

  useEffect(() => {
    for (let i = 0; i < length; i++) {
      let element = points[i];
      let point = {
        x: parseFloat(element.x),
        y: parseFloat(element.y),
        z: parseFloat(element.z),
      };
      tempBoxes.position.set(point.x, point.y, point.z);
      tempBoxes.updateMatrix();
      ref.current.setMatrixAt(i, tempBoxes.matrix);
      textures.push(i);
    }
    ref.current.instanceMatrix.needsUpdate = true;

    boxesGeometry.setAttribute(
      "textureIndex",
      new THREE.InstancedBufferAttribute(new Float32Array(textures), 1)
    );
  }, [boxesGeometry]);

  return (
    <instancedMesh
      ref={ref}
      args={[boxesGeometry, labeled ? material : materialDefault, length]}
      onPointerUp={(e) => {
        HandleClick(e);
      }}
    />
  );
};

InspectionPoints.defaultProps = {
  inspectionFile: "assets/marker_data/Marker_Data.json",
};
