/**
 * Compresses a series of points
 * @param {A list of points as object containing x, y and z coordinates} PointList 
 * @param {The factor to decrease the complexity of the path by} epsilon 
 * @returns A compressed path that retains the shape
 */

export function DouglasPeucker(PointList, epsilon) {
    //Find the point with the maximum distance
    let dmax = 0;
    let index = 0;
    let end = PointList.length;
    if (PointList.length <= 2) {
        return PointList;
    }

    for (let i = 1; i < end; i++) {
        let d = PerpDist(PointList[i], PointList[0], PointList[end - 1]);
        if (d > dmax) {
            index = i
            dmax = d
        }
    }


    let ResultList = [];

    //If max distance is greater than epsilon, recursively simplify
    if (dmax > epsilon) {
        //Recursive call
        let recResults1 = DouglasPeucker(PointList.slice(0, index - 1), epsilon)
        let recResults2 = DouglasPeucker(PointList.slice(index, end - 1), epsilon)

        //Build the result list
        ResultList = [...recResults1, ...recResults2];
    } else {
        ResultList = PointList;
    }

    //Return the result
    return ResultList;

}

/**
 * Calcualtes the distance from the point p1 to the line
 * created by the point that passes through lp1 and lp2
 * @param {The point} p1 
 * @param {The first point on the line} lp1 
 * @param {The second point on the line} lp2 
 * @returns 
 */
function PerpDist(p1, lp1, lp2) {

    var _n = Math.abs((lp2.x - lp1.x) * (lp1.y - p1.y) -
        (lp1.x - p1.x) * (lp2.y - lp1.y));

    var _d = Math.sqrt(Math.pow(lp2.x - lp1.x, 2) + Math.pow(lp2.y - lp1.y, 2));

    return _n / _d;
}