import { combineReducers } from 'redux';
import droneData from './reducers/DroneDataReducer';
import flightData from './reducers/FlightDataReducer';
import tabData from './reducers/TabDataReducer';
import cameraData from './reducers/CameraDataReducer'
import buildingData from './reducers/BuildingDataReducer';
import inspectionData from './reducers/InspectionDataReducer';
import mouseData from './reducers/MouseDataReducer';
import { createStore } from 'redux'

const reducers = combineReducers({
    //Drone Data
    DroneData: droneData,
    MouseData: mouseData,
    FlightData: flightData,
    TabData: tabData,
    CameraData: cameraData,
    BuildingData: buildingData,
    InspectionData: inspectionData,
});

export const store = createStore(reducers);