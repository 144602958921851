import React from "react";
import { FlightPath } from "./FlightPath";

export const paths = [
  {
    name: "Pilot 1, Flight 1",
    file: "assets/paths/Subject_1_Run_1.json",
    color: "#76eee6",
  },
  {
    name: "Pilot 1, Flight 2",
    file: "assets/paths/Subject_1_Run_2.json",
    color: "#4da19b",
  },
  {
    name: "Pilot 2, Flight 1",
    file: "assets/paths/Subject_2_Run_1.json",
    color: "#80ff03",
  },
  {
    name: "Pilot 2, Flight 2",
    file: "assets/paths/Subject_2_Run_2.json",
    color: "#408200",
  },
  {
    name: "Pilot 3, Flight 1",
    file: "assets/paths/Subject_3_Run_1.json",
    color: "#ffa600",
  },
  {
    name: "Pilot 3, Flight 2",
    file: "assets/paths/Subject_3_Run_2.json",
    color: "#8a5a00",
  },
  {
    name: "Pilot 4, Flight 1",
    file: "assets/paths/Subject_4_Run_1.json",
    color: "#ff008a",
  },
  {
    name: "Pilot 4, Flight 2",
    file: "assets/paths/Subject_4_Run_2.json",
    color: "#820046",
  },
];

export const FlightPaths = () => {
  return (
    <>
      {paths.map((file) => {
        return (
          <FlightPath
            flightName={file.name}
            flightFile={file.file}
            color={file.color}
            compressionFactor={0.000001}
          />
        );
      })}
    </>
  );
};
