import * as THREE from 'three';



/**
 * Given a point on a curve, and the curve itself
 * this function will return the arc length (between 0 and 1) of where
 * that point lies
 */


export function inverseLerp(point, curve) {
    return new Promise(function (resolve, reject) {

        var min = 100;
        var minI = 1;
        var dist = 0;
        for (let i = 0; i < .5; i += (1 / 500)) {
            dist = curve.getPointAt(i).distanceTo(point);
            if (dist < .5) {
                resolve(i);
                if (dist < min) {
                    minI = i;
                    min = dist;
                }
            }
            dist = curve.getPointAt(1 - i).distanceTo(point);
            if (dist < .5) {
                resolve(1 - i);
                if (dist < min) {
                    minI = (1 - i);
                    min = dist;
                }
            }
        }
        resolve(minI);
    })

}
//animation data looks like this:
// dataArray: ({ time: time, position: { x, y, z }, rotation: { pitch, roll, yaw }, velocity: { vX, vY, vZ }, speed });

export function findAnimationDataFromPoint(point, animationData) {
    return new Promise(function (resolve, reject) {

        var min = 999999;
        var dist = 0;
        var minI = 0;
        //TODO turn into Binary search
        for (let i = 0; i < animationData.dataArray.length; i++) {
            var data = animationData.dataArray[i];
            var thisPoint = new THREE.Vector3(data.position.x, data.position.y, data.position.z);
            dist = thisPoint.distanceTo(point);

            if (dist < min) {
                minI = i;
                min = dist;
            }
        }
        resolve(animationData.dataArray[minI]);
    })

}