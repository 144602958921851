import React from 'react'
import {useDispatch, useSelector} from "react-redux";
import {selectOpacity, updateOpacity} from "../../reducers/BuildingDataReducer";
import { Form } from "react-bootstrap";

export const BuildingOpacity = () => {
    const dispatch = useDispatch();
    const opacity = useSelector(selectOpacity);

    // const changeOpacity = (value) => {
    //     dispatch(hideBuilding(value));
    // };

    return (
        <div style={{
            position: "absolute",
            right: 190,
            backgroundColor: "rgba(0, 0, 0, 0.4)",
            padding: "0.5em",
            borderRadius: "10px",
            display: "inline-block"
        }}>
            <p style={{margin: "0"}}>
                {/*<Form.Check*/}
                {/*    type={'checkbox'}*/}
                {/*    label={"Hide Building"}*/}
                {/*    checked={!opacity}*/}
                {/*    onChange={(e) => changeOpacity(e.currentTarget.checked)}*/}
                {/*/>*/}
                Building Opacity
                <Form.Range
                    value={opacity}
                    onChange={e => dispatch(updateOpacity(e.target.value))}
                    min = {0}
                    max = {1}
                    step = {.05}
                    label={"Building Opacity"}
                />
            </p>
        </div>
    );
};