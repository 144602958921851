import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    lerpPoint: { x: 0, y: 0, z: 0 },
    pathCompletion: 0,
    currentTime: 0
};

const droneDataSlice = createSlice({
    name: 'droneData',
    initialState,
    reducers: {
        setPathCompletion: (state, action) => { state.pathCompletion = action.payload; },
        setLerpPoint: (state, action) => { state.lerpPoint = action.payload; },
        setCurrentTime: (state, action) => { state.currentTime = Number(action.payload); }
    }
});

export const selectLerpPoint = (state) => state.DroneData.lerpPoint;
export const selectPathCompletion = (state) => state.DroneData.pathCompletion;
export const selectCurrentTime = (state) => state.DroneData.currentTime;
export const { setPathCompletion, setCurrentTime, setLerpPoint } = droneDataSlice.actions;

export default droneDataSlice.reducer;