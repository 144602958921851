import React, { useEffect, useState } from "react";
import { ToggleButton, Card, Row, Col, Form, Badge } from "react-bootstrap";
import uuid from "react-uuid";
import {
  setHighlightedPathName,
  selectFlights,
  selectHighlightedPathName,
  selectVisibilityMatrix,
  setVisible,
} from "../../reducers/FlightDataReducer";
import { useDispatch, useSelector } from "react-redux";
export const PathToggler = (props) => {
  const PathTogglePanel = (panelProps) => {
    const pathVisibleMatrix = useSelector(selectVisibilityMatrix);
    const flights = useSelector(selectFlights);
    const totalTime =
      flights !== undefined
        ? flights[panelProps.pathName] !== undefined
          ? flights[panelProps.pathName].flightAnimationData.totalTime
          : 0
        : 0;
    const amHighlighted =
      panelProps.pathName === useSelector(selectHighlightedPathName);
    const visible =
      pathVisibleMatrix[panelProps.pathName] === undefined
        ? true
        : pathVisibleMatrix[panelProps.pathName];

    return (
      <>
        <Card bg="dark" border="light">
          <Card.Header style={{ color: panelProps.color }}>
            <Row>
              <div
                className="my-auto text-center"
                style={{ backgroundColor: panelProps.color, color: "black" }}
              >
                {panelProps.pathName}
              </div>
            </Row>
            <Row>
              <div className="my-auto col-4">{totalTime.toFixed(2)} sec</div>
              <ToggleButton
                className="my-1 col-4"
                id={panelProps.pathName + "-highlight"}
                type="checkbox"
                variant="outline-light"
                size="sm"
                checked={amHighlighted}
                value="1"
                onChange={(e) =>
                  dispatch(setHighlightedPathName(panelProps.pathName))
                }
              >
                {amHighlighted ? "Highlighted" : "Highlight"}
              </ToggleButton>
              <Form.Check
                type="switch"
                id={panelProps.pathName + "-visible"}
                aria-label={visible ? "Visible" : "Not Visible"}
                className="my-1 col-4 text-center"
                checked={visible}
                onChange={(e) =>
                  dispatch(
                    setVisible({
                      flightName: panelProps.pathName,
                      visible: e.currentTarget.checked,
                    })
                  )
                }
              ></Form.Check>
            </Row>
          </Card.Header>
        </Card>
      </>
    );
  };
  const dispatch = useDispatch();

  return (
    <>
      <Card bg="dark" border="light">
        <Card.Header>
          <Row>
            <div className="my-auto col-4 text-center">Total Time</div>
            <div className="my-auto col-4 text-center">Highlight</div>
            <div className="my-auto col-4 text-center">Visibility</div>
          </Row>
        </Card.Header>
      </Card>
      {props.paths.map((path) => (
        <PathTogglePanel pathName={path.name} color={path.color} key={uuid()} />
      ))}
    </>
  );
};

PathToggler.defaultProps = {
  paths: [
    {
      name: "Path 1",
      color: "red",
    },
    {
      name: "Path 2",
      color: "blue",
    },
    {
      name: "Path 3",
      color: "green",
    },
  ],
};
