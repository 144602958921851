import { useFrame, useThree } from "@react-three/fiber";
import React, { useEffect } from "react";
import { useMemo, useRef, useState } from "react";
import { Canvas } from "@react-three/fiber";
import * as THREE from "three";
import { useSelector, useDispatch } from "react-redux";
import {
  selectCurrentPathName,
  setLerpPoint,
  selectLerpPoint,
  selectCurrentTime,
  setRotation,
  setCurrentVelocity,
  selectCurrentVelocity,
  selectRotation,
  setCurrentTime,
  selectPathPoint,
} from "../../reducers/MouseDataReducer";
import { findAnimationDataFromPoint } from "../../utilities/InverseLerp";
import { selectFlights } from "../../reducers/FlightDataReducer";
import { Badge } from "react-bootstrap";
import { paths } from "../paths/FlightPaths";
import "../../css/MouseData.css";

export const MouseData = () => {
  const dispatch = useDispatch();
  const currentPathName = useSelector(selectCurrentPathName);
  const position = useSelector(selectLerpPoint);
  const rotation = useSelector(selectRotation);
  const velocity = useSelector(selectCurrentVelocity);
  const highlightedPathColor = currentPathName
    ? paths.find((path) => path.name === currentPathName).color
    : "white";

  const flights = useSelector(selectFlights);
  const curve =
    flights[currentPathName] === undefined
      ? undefined
      : flights[currentPathName].curve;
  const animationData =
    flights[currentPathName] === undefined
      ? undefined
      : flights[currentPathName].flightAnimationData;

  // function getLerp(){
  //  if ( curve == undefined){
  //      return new Promise((resolve, reject) => {resolve(0);})
  //  }
  //  else{
  //     return inverseLerp(position, curve, .015);
  //  }
  // }

  const currentTime = useSelector(selectCurrentTime);
  const pathPoint = useSelector(selectPathPoint);
  //update rotation and position
  useEffect(() => {
    if (animationData !== undefined) {
      //get the animation data point closest to the current position
      findAnimationDataFromPoint(
        new THREE.Vector3(pathPoint.x, pathPoint.y, pathPoint.z),
        animationData
      ).then((data) => {
        dispatch(setCurrentTime(data.time));
        dispatch(setRotation(data.rotation));
        dispatch(setCurrentVelocity(data.velocity));
        dispatch(setLerpPoint(data.position));
      });
    }
  }, [pathPoint]);

  return (
    <div className="mouse-data">
      <p>
        <b>Flight Path: </b>
        <span style={{ color: highlightedPathColor }}>{currentPathName}</span>
      </p>
      <p>
        <b>Location (m): </b>
      </p>
      <p>
        &emsp; (&nbsp;x:&nbsp;&nbsp;
        <Badge pill bg="warning" text="dark">
          {position.x.toFixed(1)}
        </Badge>
        &nbsp;,&nbsp;&nbsp;y:&nbsp;&nbsp;
        <Badge pill bg="warning" text="dark">
          {position.y.toFixed(1)}
        </Badge>
        &nbsp;,&nbsp;&nbsp;z:&nbsp;&nbsp;
        <Badge pill bg="warning" text="dark">
          {position.z.toFixed(1)}
        </Badge>
        &nbsp;)
      </p>
      <p>
        <b>Rotation (deg):</b>
      </p>
      <p>
        &emsp; (&nbsp;Pitch:&nbsp;&nbsp;
        <Badge pill bg="warning" text="dark">
          {Math.round(rotation.pitch)}&deg;
        </Badge>
        &nbsp;,&nbsp;&nbsp;Yaw:&nbsp;&nbsp;
        <Badge pill bg="warning" text="dark">
          {Math.round(rotation.yaw)}&deg;
        </Badge>
        &nbsp;,&nbsp;&nbsp;Roll:&nbsp;&nbsp;
        <Badge pill bg="warning" text="dark">
          {Math.round(rotation.roll)}&deg;
        </Badge>
        &nbsp;)
      </p>
      <p>
        <b>Velocity (m/s):</b>
      </p>
      <p>
        &emsp; (&nbsp;x:&nbsp;&nbsp;
        <Badge pill bg="warning" text="dark">
          {velocity.vX.toFixed(1)}
        </Badge>
        &nbsp;,&nbsp;&nbsp;y:&nbsp;&nbsp;
        <Badge pill bg="warning" text="dark">
          {velocity.vY.toFixed(1)}
        </Badge>
        &nbsp;,&nbsp;&nbsp;z:&nbsp;&nbsp;
        <Badge pill bg="warning" text="dark">
          {velocity.vZ.toFixed(1)}
        </Badge>
        &nbsp;)
      </p>
      <p>
        <b>Time (s):</b> {currentTime.toFixed()}
      </p>
    </div>
  );
};
