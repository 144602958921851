export class FlightAnimationData {

    dataArray = new Array();
    totalTime = 0;
    constructor(flightObj) {

        flightObj.forEach(data => {
            const {
                time,
                x,
                y,
                z,
                pitch,
                roll,
                yaw,
                vX,
                vY,
                vZ,
                speed
            } = data;

            this.dataArray.push({ time: time, position: { x, y, z }, rotation: { pitch, roll, yaw }, velocity: { vX, vY, vZ }, speed });

        });
        this.totalTime = this.dataArray[this.dataArray.length - 1].time;
        // console.log(this.dataArray);
    }


    LerpData(leftData, rightData, leftTime, rightTime, lerpTime) {

        if (leftTime === rightTime) {
            //no interpolation needed
            return leftData;
        }

        if (leftTime > rightTime) {
            var t = rightTime;
            rightTime = leftTime;
            leftTime = rightTime;
        }


        var leftScale = (rightTime - lerpTime) / (rightTime - leftTime);
        var rightScale = (lerpTime - leftTime) / (rightTime - leftTime);
        return (leftData * leftScale) + (rightData * rightScale);
    }

    FindIndexes(time) {
        //if the time exceeds the max time
        if (time > this.totalTIme) {
            return null;
        }
        if (time === this.totalTIme) { return [this.dataArray.length - 1, this.dataArray.length - 1] }
        if (time === this.dataArray[0]) { return [0, 0] }
        //if the time is less than 0
        if (time < 0) return null;

        //TODO: turn into binary search
        //search for the indexes that we need to interpolate between
        for (var i = 1; i < this.dataArray.length; i++) {
            var arrayTime = this.dataArray[i].time;
            if (arrayTime === time) return [i, i];
            if (arrayTime > time) return [i - 1, i];
        }

        return [this.dataArray.length - 1, this.dataArray.length - 1];

    }

    InterpolatePosition(time) {
        var inx = this.FindIndexes(time);
        if (inx === null) return null;
        //return interpolated position
        return {
            x: this.LerpData(
                this.dataArray[inx[0]].position.x, this.dataArray[inx[1]].position.x,
                this.dataArray[inx[0]].time, this.dataArray[inx[1]].time,
                time),
            y: this.LerpData(
                this.dataArray[inx[0]].position.y, this.dataArray[inx[1]].position.y,
                this.dataArray[inx[0]].time, this.dataArray[inx[1]].time,
                time),
            z: this.LerpData(
                this.dataArray[inx[0]].position.z, this.dataArray[inx[1]].position.z,
                this.dataArray[inx[0]].time, this.dataArray[inx[1]].time,
                time)
        }
    }
    InterpolateRotation(time) {
        var inx = this.FindIndexes(time);
        if (inx === null) return null;

        //return interpolated position
        return {
            pitch: this.LerpData(
                this.dataArray[inx[0]].rotation.pitch, this.dataArray[inx[1]].rotation.pitch,
                this.dataArray[inx[0]].time, this.dataArray[inx[1]].time,
                time),
            yaw: this.LerpData(
                this.dataArray[inx[0]].rotation.yaw, this.dataArray[inx[1]].rotation.yaw,
                this.dataArray[inx[0]].time, this.dataArray[inx[1]].time,
                time),
            roll: this.LerpData(
                this.dataArray[inx[0]].rotation.roll, this.dataArray[inx[1]].rotation.roll,
                this.dataArray[inx[0]].time, this.dataArray[inx[1]].time,
                time)
        }
    }
    InterpolateVelocity(time){
        var inx = this.FindIndexes(time);
        if (inx === null) return null;
        //return interpolated velocity
        return {
            vX: this.LerpData(
                this.dataArray[inx[0]].velocity.vX, this.dataArray[inx[1]].velocity.vX,
                this.dataArray[inx[0]].time, this.dataArray[inx[1]].time,
                time),
            vY: this.LerpData(
                this.dataArray[inx[0]].velocity.vY, this.dataArray[inx[1]].velocity.vY,
                this.dataArray[inx[0]].time, this.dataArray[inx[1]].time,
                time),
            vZ: this.LerpData(
                this.dataArray[inx[0]].velocity.vZ, this.dataArray[inx[1]].velocity.vZ,
                this.dataArray[inx[0]].time, this.dataArray[inx[1]].time,
                time)
        }
    }
    InterpolateSpeed(time){
        var inx = this.FindIndexes(time);
        if (inx === null) return null;
        //return interpolated velocity
        return this.LerpData(
                this.dataArray[inx[0]].speed, this.dataArray[inx[1]].speed,
                this.dataArray[inx[0]].time, this.dataArray[inx[1]].time,
                time)
    }
}