import React from 'react'
import {useDispatch, useSelector} from "react-redux";
import { selectLabeled, setLabeled, selectSelectedIndex, selectInspectionPoints } from "../../reducers/InspectionDataReducer";
import { Form } from "react-bootstrap";

export const InspectionPointsLabel = () => {
  const dispatch = useDispatch();
  const labeled = useSelector(selectLabeled);
  const index = useSelector(selectSelectedIndex);
  const points = useSelector(selectInspectionPoints);

  const changeLabeled = (value) => {
    dispatch(setLabeled(value));
  };

  return (
    <div style={{
      position: "absolute",
      right: 465,
      backgroundColor: "rgba(0, 0, 0, 0.4)",
      padding: "0.5em",
      borderRadius: "10px",
      display: "inline-block",
      zIndex: 0,
    }}>
      <p style={{margin: "0"}}>
        <Form.Check
          type={'checkbox'}
          label={"Label Inspection Points"}
          checked={labeled}
          onChange={(e) => changeLabeled(e.currentTarget.checked)}
        />
        <div style={{fontSize: 13, textAlign: "center"}}>
          {(index == null) ? "Click point to view data" :
            "X: " + points[index].x + " Y: " + points[index].y + " Z: " + points[index].z}
        </div>
      </p>
    </div>
  );
};