/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import * as THREE from 'three';

export default function Building({ ...props }) {
  const group = useRef();
  const { nodes } = useGLTF(process.env.PUBLIC_URL + props.modelPath);

  const material = new THREE.MeshStandardMaterial({
    flatShading: true,
    metalness: 1,
    envMapIntensity: 1.8,
    opacity: props.opacity,
    transparent: props.transparent,
    depthWrite: !props.transparent
  });

  return (
    <group ref={group} {...props} dispose={null}>
      <mesh
        geometry={nodes.Point_Cloud_Model.geometry}
        material={material}
        position={[props.translateX, props.translateY, props.translateZ]}
        rotation={[props.rotateX, props.rotateY, props.rotateZ]}
        scale={props.scale}
      />
    </group>
  )
}


