/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
author: Mateus Schwaab (https://sketchfab.com/Mehrus)
license: CC-BY-4.0 (http://creativecommons.org/licenses/by/4.0/)
source: https://sketchfab.com/3d-models/drone-301fdddb9cd9420faa1174e68e0b56ab
title: Drone
*/

import React, {useRef, useState, useEffect, useMemo} from 'react'
import { useGLTF, Line } from '@react-three/drei'
import { useFrame } from '@react-three/fiber';
import { useSelector } from 'react-redux';
import { selectCurrentTime } from '../../reducers/DroneDataReducer'
import { selectHighlightedPath, selectFlights } from '../../reducers/FlightDataReducer'
import * as THREE from 'three'

export default function Drone({ ...props }) {
  const group = useRef()
  const mesh = useRef()
  const velArrow = useRef();
  const { nodes, materials } = useGLTF(process.env.PUBLIC_URL + '/assets/models/drone/scene.gltf')
  const currentTime = useSelector(selectCurrentTime);
  const highlightedPath = useSelector(selectHighlightedPath);
  const [position, setPosition] = useState({ x: 0, y: 0.2, z: 0 });
  const [rotation, setRotation] = useState({ pitch: 0, roll: 0, yaw: 0 });
  useEffect(() => {

    if (!(highlightedPath === undefined || currentTime < 0 || currentTime > highlightedPath.flightAnimationData.totalTime)) {
      setPosition(highlightedPath.flightAnimationData.InterpolatePosition(currentTime));
      setRotation(highlightedPath.flightAnimationData.InterpolateRotation(currentTime));
    }
  }, [currentTime]);

  useFrame((state, delta) => {
    //the lerp is to smooth the rotation/position so its less harsh

    group.current.position.x = THREE.MathUtils.lerp(group.current.position.x,
      position.x, .25);
    group.current.position.y = THREE.MathUtils.lerp(group.current.position.y,
      position.y, .25);
    group.current.position.z = THREE.MathUtils.lerp(group.current.position.z,
      position.z, .25);


    group.current.rotation.y = THREE.MathUtils.lerp(group.current.rotation.y,
      THREE.MathUtils.degToRad(rotation.yaw), .25);

    setPoints([[group.current.position.x
        , group.current.position.y - 10,
        group.current.position.z],
      [position.x, 10 + position.y, position.z]]);
  });

  const [points, setPoints] = useState([[0, -10, 0],[0, 10, 0]]);

  return (
    <group>
      <group ref={group} {...props} dispose={null} scale={[.25, .25, .25]}>
        <mesh ref={mesh}
              geometry={nodes.defaultMaterial.geometry} material={materials.DefaultMaterial} />
        <group scale={[4, 4, 4]} position={[0, 0, 4]}>
          <mesh ref={mesh} scale={[1, 1, 1]} rotation={[THREE.MathUtils.degToRad(90), 0, 0]}>
            <cylinderBufferGeometry attach="geometry" args={[.5, 0, 2]}
            />
            <meshBasicMaterial attach="material" color="white" transparent={true} depthWrite={false} opacity={.25} />
          </mesh>
        </group>
      </group>
      {/*uncomment to show vertical location beam for drone*/}
      {/*<Line ref={velArrow} points={points} color={'white'} lineWidth={5} transparent={true} opacity={0.3} depthWrite={false}/>*/}
    </group>
  )
}

useGLTF.preload(process.env.PUBLIC_URL + '/assets/models/drone/scene.gltf')
