import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    lerpPoint: { x: 0, y: 0, z: 0 },            //the point we actually find on the path (at current time)
    pathPoint: { x: 0, y: 0, z: 0 },            //the point the user clicked on 
    currentVelocity: { vX: 0, vY: 0, vZ: 0 },      //the velocity at lerpPoint
    rotation: { pitch: 0, yaw: 0, roll: 0 },    //the rotation at lerpPoint
    currentPathName: "",                        //the name of the path,
    showMenu: false,                            //are we showing the menu?
    potentialPathData: { name: "", point: { x: 0, y: 0, z: 0 }, distance: 999999 },   //the path we just clicked on to potentially set things
    currentTime: 0
};

const mouseDataSlice = createSlice({
    name: 'mouseData',
    initialState,
    reducers: {
        setLerpPoint: (state, action) => { state.lerpPoint = action.payload; },
        setPathPoint: (state, action) => { state.pathPoint = action.payload },
        setRotation: (state, action) => { state.rotation = action.payload; },
        setCurrentPathName: (state, action) => { state.currentPathName = action.payload; },
        setCurrentTime: (state, action) => { state.currentTime = Number(action.payload); },
        setCurrentVelocity: (state, action) => { state.currentVelocity = action.payload; },
        setShowMenu: (state, action) => { state.showMenu = action.payload },
        setPotentialPathData: (state, action) => { state.potentialPathData = action.payload }
    }
});

export const selectLerpPoint = (state) => state.MouseData.lerpPoint;
export const selectCurrentPathName = (state) => state.MouseData.currentPathName;
export const selectRotation = (state) => state.MouseData.rotation;
export const selectPathPoint = (state) => state.MouseData.pathPoint;
export const selectCurrentTime = (state) => state.MouseData.currentTime;
export const selectCurrentVelocity = (state) => state.MouseData.currentVelocity;
export const selectShowMenu = (state) => state.MouseData.showMenu;
export const selectPotentialPathData = (state) => state.MouseData.potentialPathData;
export const { setShowMenu, setLerpPoint, setPathPoint, setRotation, setCurrentPathName, setCurrentTime,
    setCurrentVelocity, setPotentialPathData } = mouseDataSlice.actions;

export default mouseDataSlice.reducer;