import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import './App.css';
import React, {Fragment} from "react"
import { Tabs, Tab, Container } from "react-bootstrap";
import { Sidebar } from "./components/sidebar/Sidebar";
import { DroneViewer } from "./components/sidebar/DroneViewer";
import { DroneData } from "./components/sidebar/DroneData";
import { AnalyzeViewer } from "./components/viewers/AnalyzeViewer";
import { AlignViewer } from "./components/viewers/AlignViewer";
import { PathToggler } from './components/paths/PathToggler';
import { BuildingTranslate, BuildingRotate, BuildingScale, DefaultAlignment } from './components/sidebar/AlignSidebar';
import { Upload } from "./components/upload/Upload";
import { Suspense } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectCurrentTab, setCurrentTab } from './reducers/TabDataReducer';
import { paths } from './components/paths/FlightPaths';
import { ResetView } from "./components/misc/ResetView";
import { MouseData } from "./components/sidebar/MouseData";
import { InspectionPoints } from "./components/inspection_points/InspectionPoints";
import { InspectionPointsLabel } from "./components/inspection_points/InspectionPointsLabel";
import { BuildingOpacity } from "./components/model/BuildingOpacity";
import { Help } from "./components/help/Help";

export const App = () => {
  const dispatch = useDispatch();
  const currentTab = useSelector(selectCurrentTab);
  const resetView = <ResetView />;
  const inspectionPoints = <InspectionPoints/>;
  const inspectionPointsLabel = <InspectionPointsLabel />;
  const buildingOpacity = <BuildingOpacity />;

  return (
    <Suspense fallback="test">
      <Tabs activeKey={currentTab} id="controlled-tab-example" onSelect={(tab) => dispatch(setCurrentTab(tab))}>
        <Tab eventKey="upload" title="Upload">
          <Upload />
        </Tab>
        <Tab eventKey="align" title="Align">
          <div className='flex'>
            <Sidebar defaultActiveKeys={['1', '2', '3']}>
              <DefaultAlignment name="Default Alignment" info={"This button will set a default alignment"}/>
              <BuildingTranslate name="Building Translate" info={"Use slider and buttons to adjust the building position"}/>
              <BuildingRotate name="Building Rotate" info={"Use slider and buttons to adjust the building rotation"}/>
              <BuildingScale name="Building Scale" info={"Use slider and buttons to adjust the building scale"}/>
            </Sidebar>
            <AlignViewer name={"AlignViewer"} resetView={resetView} inspectionPointsLabel={inspectionPointsLabel} inspectionPoints={inspectionPoints} buildingOpacity={buildingOpacity}/>
          </div>
        </Tab>
        <Tab eventKey="analyze" title="Analyze">
          <div className='flex noPadding' style={{height: "94vh"}}>
            <Sidebar defaultActiveKeys={['0']} className="noPadding">
              <Container name="Viewer" className="noPadding" info={
                <Fragment>
                  First-person POV of Drone Model on the Highlighted Flight Path<br/>
                  <ul>
                    <li>Play/pause flight simulation</li>
                    <li>Use slider to adjust flight time</li>
                    <li>Adjust animation speed</li>
                  </ul>
                </Fragment>}>
                <DroneViewer name="Viewer" inspectionPoints={inspectionPoints} />
              </Container>
              <DroneData name="Drone Data" info={
                <Fragment>
                  Position, speed, and rotation data for the drone on the Highlighted flight path<br/>
                  <ul>
                    <li>See "Path Toggle" to change the Highlighted flight path</li>
                  </ul>
                </Fragment>}/>
              <MouseData name="Mouse Data" info={
                <Fragment>
                  Position, speed, and rotation data for the drone on mouse-selected path<br/>
                  <ul>
                    <li>Click a point on any flight path to view Mouse Data</li>
                  </ul>
                </Fragment>}/>
              <PathToggler name="Path Toggle" paths={paths} info={
                <Fragment>
                  Manage flight path visibility and highlight<br/>
                  <ul>
                    <li>Click buttons to change visibility and highlight for each path</li>
                    <li>Only one path can be highlighted at a time</li>
                  </ul>
                </Fragment>}/>
            </Sidebar>
            <AnalyzeViewer name={"AnalyzeViewer"} resetView={resetView} inspectionPointsLabel={inspectionPointsLabel} inspectionPoints={inspectionPoints} buildingOpacity={buildingOpacity}/>
          </div>
        </Tab>
        <Tab eventKey="help" title="Help">
          <Help/>
        </Tab>
      </Tabs>
    </Suspense>
  )
};
