import React  from 'react';
import { Container, Row, Col, Button } from "react-bootstrap";
import {UploadForm} from "./UploadForm";


export const Upload = (props) => {
    return (

                <Container className="my-auto pt-5">
                    <Row className="text-center">
                        <h1>Upload Files</h1>
                    </Row>
                    <Row>
                        <Col md={{ span: 6, offset: 3 }}>
                            <UploadForm/>
                        </Col>
                    </Row>
                </Container>

    );
}