import React from 'react'
import {useDispatch, useSelector} from "react-redux";
import {selectPos, selectRot, setResetView} from "../../reducers/CameraDataReducer";
import Button from "react-bootstrap/Button";
import {invalidate} from "@react-three/fiber";

export const ResetView = () => {
  const dispatch = useDispatch();
  const curPos = useSelector(selectPos);
  const curRot = useSelector(selectRot);

  const resetCamera = () => {
    // console.log("clicked");
    invalidate();
    dispatch(setResetView(-1));
  };

  return (
    <div style={{
      position: "absolute",
      right: 0,
      backgroundColor: "rgba(0, 0, 0, 0.4)",
      padding: "0.5em",
      borderRadius: "10px",
      display: "inline-block",
      zIndex:"0"
    }}>
      <img src={process.env.PUBLIC_URL + '/camera_icon.png'} alt="Camera Icon" style={{height: "2.5em", paddingRight: "0.5em", verticalAlign: "middle"}}/>
      <Button variant="secondary" onClick={resetCamera} style={{display: "inline-block", marginRight: "0.5em", verticalAlign: "middle"}}>Reset View</Button>
      <p style={{margin: "0"}}>
        Position:
        <strong> X:</strong> {curPos.x}
        <strong> Y</strong> {curPos.y}
        <strong> Z:</strong> {curPos.z}
      </p>
    </div>
  );
};