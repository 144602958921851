import React from "react";
import { Html } from "@react-three/drei";
import {
  Button,
  Offcanvas,
  ButtonGroup,
  Row,
  Col,
  Container,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  selectShowMenu,
  setShowMenu,
  selectPotentialPathData,
  setPotentialPathData,
} from "../../reducers/MouseDataReducer";
import {
  setPathPoint,
  setCurrentPathName,
} from "../../reducers/MouseDataReducer";
import {
  setHighlightedPathName,
  setVisible,
  selectFlights,
} from "../../reducers/FlightDataReducer";
import { paths } from "../paths/FlightPaths";
import { DashSquareFill } from "react-bootstrap-icons";
export const RightClickMenu = (props) => {
  const dispatch = useDispatch();
  const flights = useSelector(selectFlights);
  const potentialPathData = useSelector(selectPotentialPathData);
  const show = useSelector(selectShowMenu); // hide menu
  const highlightedPathColor = !potentialPathData
    ? "white"
    : potentialPathData.name
    ? paths.find((path) => path.name === potentialPathData.name).color
    : "white";
  return (
    <Html prepend center>
      <Offcanvas
        show={show}
        onHide={close}
        variant="secondary"
        placement="bottom"
        style={{ background: "#2D2D33" }}
      >
        <Offcanvas.Header closeButton closeVariant="white">
          <Offcanvas.Title>
            <Container>
              <Row>
                <Col md="auto">
                  <DashSquareFill style={{ color: highlightedPathColor }} />
                </Col>
                <Col md="auto">
                  {potentialPathData && (
                    <h1 style={{ color: "white" }}>{potentialPathData.name}</h1>
                  )}
                </Col>
              </Row>
            </Container>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <ButtonGroup>
            <Button
              variant="primary"
              onClick={() => {
                dispatch(setHighlightedPathName(potentialPathData.name));
                close();
              }}
            >
              Highlight Path
            </Button>

            <Button
              variant="warning"
              onClick={() => {
                dispatch(setPathPoint(potentialPathData.point));
                dispatch(setCurrentPathName(potentialPathData.name));
                close();
              }}
            >
              Move Drone Here
            </Button>
            <Button
              variant="danger"
              onClick={() => {
                dispatch(
                  setVisible({
                    flightName: potentialPathData.name,
                    visible: false,
                  })
                );
                close();
              }}
            >
              Hide Path
            </Button>
          </ButtonGroup>
        </Offcanvas.Body>
      </Offcanvas>
    </Html>
  );

  function close() {
    dispatch(setPotentialPathData(null));
    dispatch(setShowMenu(false));
  }
};
