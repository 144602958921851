import React, { useMemo, useRef } from "react";
import { Curve } from "./Curve";
import { DouglasPeucker } from "../../utilities/PathCompression";
import { useSelector, useDispatch } from "react-redux";
import {
  updateFlightData,
  selectFlights,
} from "../../reducers/FlightDataReducer";
import { useEffect } from "react";
import { invalidate } from "@react-three/fiber";
import {
  selectVisibilityMatrix,
  selectHighlightedPathName,
} from "../../reducers/FlightDataReducer";
import { setPotentialPathData } from "../../reducers/MouseDataReducer";
import * as THREE from "three";
import { selectShowMenu, setShowMenu } from "../../reducers/MouseDataReducer";
import { Line } from "@react-three/drei";
import {
  selectCurrentPathName,
  selectPotentialPathData,
} from "../../reducers/MouseDataReducer";
const MAX_POINTS = 500;

/*
 Draws a flight path from file
 Props:
  - flightFile: JSON file containing flight data
  - resolution: smoothness of the curve
  - radius: radius of the tube
  - color: color of the tube
 */
export const FlightPath = (props) => {
  const dispatch = useDispatch();
  const flights = useSelector(selectFlights);
  const pathVisibleMatrix = useSelector(selectVisibilityMatrix);
  const visible =
    pathVisibleMatrix[props.flightName] === undefined
      ? true
      : pathVisibleMatrix[props.flightName];
  const highlighted =
    useSelector(selectHighlightedPathName) === props.flightName;
  const curve =
    flights[props.flightName] === undefined
      ? undefined
      : flights[props.flightName].curve;
  const potentialPathData = useSelector(selectPotentialPathData);

  // console.log(flights);
  useEffect(() => {
    invalidate();

    dispatch(
      updateFlightData({
        flightName: props.flightName,
        flightFile: props.flightFile,
        compressionFactor: props.compressionFactor,
      })
    );
    // console.log(flights);
  }, []);

  function HandleDown(e) {
    const { distance, point, button } = e;
    if (button !== 2) return;
    e.stopPropagation();
    //check if path data is null or this point is closer
    if (
      potentialPathData === null ||
      potentialPathData.name == null ||
      potentialPathData.distance > distance
    ) {
      //dispatch(setShowMenu(true));
      dispatch(
        setPotentialPathData({
          name: props.flightName,
          point: point,
          distance: distance,
        })
      );
    }
  }
  function HandleUp(e) {
    const { distance, point, button } = e;
    if (button !== 2) return;
    e.stopPropagation();
    if (
      potentialPathData === null ||
      potentialPathData.name == null ||
      potentialPathData.name != props.flightName ||
      potentialPathData.distance > distance
    ) {
      dispatch(setPotentialPathData(null));
      dispatch(setShowMenu(false));
    } else {
      dispatch(setShowMenu(true));
    }
  }
  return visible ? (
    <group position={[0, 0, 0]}>
      {
        <Curve
          curve={curve}
          color={props.color}
          radius={highlighted ? props.radius : 0.005}
          resolution={props.resolution}
          rotateX={props.rotateX}
          rotateY={props.rotateY}
          rotateZ={props.rotateZ}
          translateX={props.translateX}
          translateY={props.translateY}
          translateZ={props.translateZ}
          isMainView={props.isMainView}
        />
      }
      <Line
        points={
          flights[props.flightName]
            ? flights[props.flightName].curve.points
            : [[0, 0, 0]]
        } // Array of points
        color="black" // Default
        lineWidth={25} // In pixels (default)
        dashed={false} // Default
        onPointerDown={HandleDown}
        onPointerUp={HandleUp}
        transparent
        opacity={0}
        depthWrite={false}
      />
    </group>
  ) : null;
};

FlightPath.defaultProps = {
  flightName: "Flight 1",
  flightFile: "assets/paths/Subject_1_Run_1.json",
  color: "yellow",
  radius: 0.025,
  resolution: MAX_POINTS / 2,
  compressionFactor: 0.001,
  rotateX: 0,
  rotateY: 0,
  rotateZ: 0,
  translateX: 0,
  translateY: 0,
  translateZ: 0,
};
