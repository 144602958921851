import React  from 'react';
import { Container, Row, Col, CardGroup, Card } from "react-bootstrap";
import { TutorialVideo } from "./TutorialVideo";


export const Help = () => {
  return (
    <Container className="my-auto pt-5">
      <Row className="text-center">
        <h1>Help/FAQ</h1>
      </Row>
      <Row>
        <Col md={{ span: 10, offset: 1 }}>
          {/*<TutorialVideo embedId='_RxPgZC0XiI' videoHeading='Scenario 1 (novice pilot)' videoDescription='(no audio) Scenario 1 is an example use case for the application where the user is a novice drone pilot who is looking to learn about a particular drone inspection flight path.'/>*/}
          {/*<TutorialVideo embedId='8E70PfJ9KHo' videoHeading='Scenario 2 (expert pilot)' videoDescription='(no audio) Scenario 2 is an example use case for the application where the user is an expert drone pilot who is looking to dive deeper into how they might replicate a drone inspection based on previous inspection flight paths. This experienced pilot aligns the scene and explores multiple flight paths.'/>*/}
          <TutorialVideo embedId='COTesFA8agI' videoHeading='Tutorial' videoDescription='An introductory tutorial to the features of the 3D-rone application.'/>
          <h3>Camera Controls</h3>
          <Row>
            <Col className="col-sm-12 col-md-6 col-lg-6" style={{backgroundColor: "none"}}>
              <Card border="dark" bg="dark" style={{margin: "5px"}}>
                <Card.Header>Position and Rotation</Card.Header>
                <Card.Img variant="top" src={process.env.PUBLIC_URL + '/camera-controls.png'} style={{height: "5em", width: "15em", margin: "auto"}} />
                <Card.Body>
                  <Card.Text>
                    <h6>Rotation:</h6>
                    <p>Click and Drag OR One-finger Touchscreen Drag</p>
                    <h6>Position (up, down, left, right):</h6>
                    <p>Ctrl+Click and Drag OR Two-finger Touchscreen Drag</p>
                    <h6>Zoom In/Out:</h6>
                    <p>Use scroll wheel OR use normal zoom gesture for your device</p>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col className="col-sm-12 col-md-4 col-lg-6">
              <Row>
                <Card border="dark" bg="dark" style={{margin: "5px"}}>
                  <Card.Header>Reset View</Card.Header>
                  <Card.Img variant="top" src={process.env.PUBLIC_URL + '/resetView.png'} style={{height: "5em", width: "12em", margin: "auto"}}/>
                  <Card.Body>
                    <Card.Text>
                      Click "Reset View" to reset the camera back to the origin.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Row>
              <Row>
                <Card border="dark" bg="dark" style={{margin: "5px"}}>
                  <Card.Header>ViewCube</Card.Header>
                  <Card.Img variant="top" src={process.env.PUBLIC_URL + '/viewCube.png'} style={{height: "5em", width: "5em", margin: "auto"}}/>
                  <Card.Body>
                    <Card.Text>
                      Click on the sides of the ViewCube to snap the camera to that perspective
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Row>
            </Col>
          </Row>
          <br/>
          <Row>
            <Col className="col-sm-12 col-md-4 col-lg-4">
              <h3>Inspection Points</h3>
                  <Card border="dark" bg="dark">
                    <Card.Header>Label Points</Card.Header>
                    <Card.Img variant="top" src={process.env.PUBLIC_URL + '/inspection_point_default.png'} style={{height: "5em", width: "5em", margin: "auto"}}/>
                    <Card.Body>
                      <Card.Text>
                        Inspection points are represented with red cubes. Toggle "Label Inspection Points" to label each point with a unique letter:
                      </Card.Text>
                    </Card.Body>
                    <Card.Img variant="bottom" src={process.env.PUBLIC_URL + '/inspection_point_labeled.png'} style={{height: "5em", width: "5em", margin: "auto"}}/>
                  </Card>
            </Col>
            <Col className="col-sm-12 col-md-4 col-lg-4">
              <h3>Quick Drone</h3>
              <Card border="dark" bg="dark">
                <Card.Header>Quick Drone Model</Card.Header>
                <Card.Img variant="top" src={process.env.PUBLIC_URL + '/mouseDrone.png'} style={{height: "10em", width: "15em", margin: "auto"}}/>
                <Card.Body>
                  <Card.Text>
                    For the transparent model, the red line represents the velocity of the drone at that point. Information is displayed in "Quick Drone Data".
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col className="col-sm-12 col-md-4 col-lg-4">
              <h3>Path Options</h3>
              <Card border="dark" bg="dark">
                <Card.Header>Path Options Menu</Card.Header>
                <Card.Img variant="top" src={process.env.PUBLIC_URL + '/rightClickMenu.png'} style={{height: "8em", width: "18em", margin: "auto"}}/>
                <Card.Body>
                  <Card.Text>
                    <strong>Right-click</strong> a point on a visible path to highlight, change visibility, or move the transparent (Quick Drone) drone
                    model to that position
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <br/>
          <h3>Highlighted Path</h3>
          <Row>
            <Col className="col-sm-12 col-md-12 col-lg-12">
              <Card border="dark" bg="dark">
                <Card.Header>About</Card.Header>
                <Card.Img variant="top" src={process.env.PUBLIC_URL + '/droneSimulation.png'} style={{height: "30em", width: "40em", margin: "auto"}}/>
                <Card.Body>
                  <Card.Text>
                    <p>The highlighted path can be chosen to view a <strong>simulated drone flight</strong> along that path.
                      <ul>
                        <li>This path can be selected in the "Path Toggle" in the Analyze sidebar menu</li>
                        <li>Only one path can be highlighted at a time</li>
                        <li>The first-person POV of the simulated flight is in "Viewer" and speed, position,
                          and rotation data of the drone is in "Drone Data"</li>
                      </ul>
                    </p>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <br/>
          <h3>The Drone</h3>
          <div className="sketchfab-embed-wrapper"> 
          <iframe title="Drone" frameBorder="0" allowFullScreen mozallowfullscreen="true" webkitallowfullscreen="true" allow="autoplay; fullscreen; xr-spatial-tracking" xr-spatial-tracking="true" execution-while-out-of-viewport="true" execution-while-not-rendered="true" web-share="true" src="https://sketchfab.com/models/301fdddb9cd9420faa1174e68e0b56ab/embed"> 
          </iframe> 
          </div>
          <p style={{fontSize:"13px", fontWeight:"normal", margin:"5px", color:"#4A4A4A"}}> <a href="https://sketchfab.com/3d-models/drone-301fdddb9cd9420faa1174e68e0b56ab?utm_medium=embed&utm_campaign=share-popup&utm_content=301fdddb9cd9420faa1174e68e0b56ab" target="_blank" style={{fontWeight:"bold", color:"#1CAAD9"}}> Drone </a> by <a href="https://sketchfab.com/Mehrus?utm_medium=embed&utm_campaign=share-popup&utm_content=301fdddb9cd9420faa1174e68e0b56ab" target="_blank" style={{fontWeight:"bold", color:"#1CAAD9"}}> Mateus Schwaab </a> on <a href="https://sketchfab.com?utm_medium=embed&utm_campaign=share-popup&utm_content=301fdddb9cd9420faa1174e68e0b56ab" target="_blank" style={{fontWeight:"bold", color:"#1caad9"}}>Sketchfab</a></p>
          </Col>
        </Row>
        <br/>
        <Row className="text-center">
            <h1>Additional Info</h1>
        </Row>
        <Row>
            <Col md={{ span: 10, offset: 1 }}>
                <h3>About 3D_rone</h3>
                <h5>Application Background</h5>
                <p>Large buildings must be routinely inspected in order to ensure safety. Specifically, buildings
                    such as sports arenas must have the ceiling trusses inspected. Each "joint/node" where the
                    truss meets must be visually inspected for defects. Traditionally, this has been an arduous
                    process, done by using scissor lifts and continually going up/down many times in order to cover
                    the entire space. This obviously is very inefficient and slow. Due to the difficult nature of
                    the task, it was not uncommon for these inspections to be skipped altogether. With the advent
                    of modern drone technology, the task of inspecting large buildings has been streamlined.</p>
                <p>With that being said, flying drones is a difficult task. It is very much subject to human error
                    and crashes may occur frequently when flying in difficult or unfamiliar environments.
                    Additionally, in indoors environments there is little or no GPS signal, and so flights must
                    be manual. Given that flying in unfamiliar indoor environments is so difficult, it would be
                    beneficial to provide some medium for training and familiarizing novice pilots to fly in a
                    particular space before making the flight on-site. That is, to familiarize and train people
                    to repeat or perform a similar flight path to that of a path taken during an inspection by an
                    expert drone pilot.</p>
                <h5>The Application</h5>
                <p>This app seeks to provide a web-based method of visualizing drone flights used in interior
                    building inspections, or wherever autonomous GPS-driven flights are not feasible. This
                    visualization will enable drone pilots to better understand the flight paths of professional
                    pilots who have already conducted simulated inspections within the particular space. Delivering
                    this functionality is contingent on communicating spatial flight path data intuitively within
                    a 3D space.</p>
            </Col>
        </Row>
    </Container>
  );
}