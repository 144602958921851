import React from "react";
import { Tube } from "@react-three/drei";
import { Slider } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { setCurrentPathName } from "../../reducers/MouseDataReducer";
/*
 Draws a tube curve.
 Props:
  - points: array of objects with x, y, z values
  - resolution: smoothness of the curve
  - radius: radius of the tube
  - color: color of the tube
 */
export const Curve = (props) => {
  const dispatch = useDispatch();
  if (props.curve === undefined) {
    return null;
  } else {
    // console.log(props.curve);

    return (
      <Tube
        args={[
          props.curve, // curve
          props.curve.points.length * props.resolution, // tubular segments
          props.radius, // radius
          10, // radial segments
          false, // closed
        ]}
        position={[props.translateX, props.translateY, props.translateZ]}
        rotation={[
          (props.rotateX * Math.PI) / 180.0,
          (props.rotateY * Math.PI) / 180.0,
          (props.rotateZ * Math.PI) / 180.0,
        ]}
        onPointerUp={props.onPointerUp}
      >
        <meshPhongMaterial attach="material" color={props.color} />
      </Tube>
    );
  }
};

Curve.defaultProps = {
  rotateX: 0,
  rotateY: 0,
  rotateZ: 0,
  translateX: 0,
  translateY: 0,
  translateZ: 0,
  radius: 1,
  curve: undefined,
};
