import React from 'react'
import Drone from './Drone'
import Building from './Building'
import { selectCoordinates, selectRotation, selectScale, selectTransparent, selectOpacity } from '../../reducers/BuildingDataReducer'
import { useSelector } from 'react-redux'
import { useGLTF } from '@react-three/drei'
import MouseArrow from './MouseArrow'

/*
 Draws glb model
 Props:
  - rotateX: rotates around <1, 0, 0> in world coords
  - rotateY: rotates around <0, 1, 0> in world coords
  - rotateZ: rotates around <0, 0, 1> in world coords
  - translateX: translate on x axis
  - translateY: translate on y axis
  - translateZ: translate on z axis
  - modelPath: path to the model

  Default props found below.
 */
export const Model = (props) => {
  useGLTF.preload(process.env.PUBLIC_URL + props.modelPath)
  const coordinates = useSelector(selectCoordinates)
  const rotation = useSelector(selectRotation)
  const scale = useSelector(selectScale)
  const transparent = useSelector(selectTransparent)
  const opacity = useSelector(selectOpacity)
  return (
    <>
      <Building
        rotateX={rotation.pitch * Math.PI / 180.0}
        rotateY={rotation.yaw * Math.PI / 180.0}
        rotateZ={rotation.roll * Math.PI / 180.0}
        translateX={coordinates.x}
        translateY={coordinates.y}
        translateZ={coordinates.z}
        scale={scale}
        transparent={transparent}
        opacity={opacity}
        modelPath={props.modelPath}
      />
      {!props.droneView && <MouseArrow/>}
      {!props.droneView && <Drone />}
    </>
  )
};

Model.defaultProps = {
  modelPath: "/assets/models/Drone_Space_Low_Res.glb",
  droneView: false
};