import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    points: {},
    length: 0,
    labeled: 0,
    selectedIndex: null,
}

const inspectionDataSlice = createSlice({
    name: 'inspectionData',
    initialState,
    reducers: {
        updateInspectionData: (state, action) => {
            const
                {
                    inspectionFile,
                } = action.payload;

            let request = new XMLHttpRequest();
            request.open("GET", process.env.PUBLIC_URL + "/" + inspectionFile, false);
            request.send(null);
            let inspectionObj = JSON.parse(request.responseText);

            let points = [];
            for (let i = 0; i < inspectionObj.length; i++) {
                let element = inspectionObj[i];
                let point = { x: parseFloat(element.x), y: parseFloat(element.y), z: parseFloat(element.z) };
                points.push(point);
            }
            state.points = points;
            state.length = inspectionObj.length;
        },
        setLabeled: (state, action) => { state.labeled = action.payload },
        updateSelectedIndex: (state, action) => { state.selectedIndex = action.payload },
    }
});

export const selectInspectionPoints = (state) => state.InspectionData.points;
export const selectInspectionLength = (state) => state.InspectionData.length;
export const selectLabeled = (state) => state.InspectionData.labeled;
export const selectSelectedIndex = (state) => state.InspectionData.selectedIndex;
export const { updateInspectionData, setLabeled, updateSelectedIndex} = inspectionDataSlice.actions;

export default inspectionDataSlice.reducer;