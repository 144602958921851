import React from "react";
import { Html } from "@react-three/drei";
import { Spinner, Alert, Row, Col, Placeholder, Container } from "react-bootstrap";
import "../../css/Loading.css";

export const Loading = (props) => {
    return (
        <Html fullscreen>
            <Placeholder as={Alert} animation="wave" bg="dark">
                <Container className="loading-message">
                    <Row className="loading-message-row">
                        <Col className="loading-message-col" xs={12}>
                            <Spinner className="spinner" animation="border" variant="primary" />
                            <h1 style={{ display: "inline" }}>{props.message}</h1>
                        </Col>
                    </Row>
                </Container>
            </Placeholder>
        </Html>
    );
};

Loading.defaultProps = {
    message: "Loading...",
};
